// auth-service.js
import { API_BASE_URL } from "../../common/index";
import { request,NoAuthRequest } from "../../utils/ApiUtils";
export function resetPassword(changePasswordRequest) {
     
    return NoAuthRequest({
        url: API_BASE_URL + '/api/user/reset',
        method: 'POST',
        data: JSON.stringify(changePasswordRequest),
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
export function getActiveUser() {
    return request({
        url: API_BASE_URL + "/api/user/user",
        method: 'GET'
    });
}
