import React, { Component } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import { NavLink } from 'react-router-dom';

class FooterPage extends Component {
  constructor() {
    super();
    this.state = {
      inputValue: "yo",
    };
  }

  render() {
    return (
      <div className="homefooter  inner">
        <footer>
          <div className="wrapper">
            <div className="footer-main-outer">
              <ul className="footer-link">
                <li className="fot-res-menu">
                  {" "}
                  {/* <a href="/aboutus"> About Us </a> */}
                  <NavLink
                      exact
                      activeClassName="active"
                      to="aboutus"
                    >
                      About Us
                  </NavLink>
                  {" "}
                </li>
                <li>
                  {" "}
                  {/* <a href="/contactus"> Contact Us </a> */}
                  <NavLink
                      exact
                      activeClassName="active"
                      to="contactus"
                    >
                      Contact Us
                  </NavLink>
                  {" "}
                </li>
                <li>
                  {" "}
                  {/* <a href="/privacy"> Privacy Policy </a> */}
                  <NavLink
                      exact
                      activeClassName="active"
                      to="privacy"
                    >
                      Privacy Policy
                  </NavLink>
                  {" "}
                </li>
                <li>
                  {" "}
                  {/* <a href="/terms"> Terms Of Service </a> */}
                  <NavLink
                      exact
                      activeClassName="active"
                      to="terms"
                    >
                      Terms Of Service
                  </NavLink>
                  {" "}
                </li>
              </ul>
              <div className="follow footer">
                <span>Follow us on:</span>
                <a
                  className = "linkedin_clr"
                  rel="noopener noreferrer"
                  target="_blank"
                  href=""
                  aria-label="Linkedin"
                >
                 <i className="fa fa-linkedin-square " aria-hidden="true"></i>
                  {""}
                </a>
                <a
                  rel="noopener noreferrer"
                  className = "twitter_clr"
                  target="_blank"
                  href=""
                  aria-label="Twiiter"
                >
                    <i className="fa fa-twitter-square" aria-hidden="true"></i>
                  {""}
                </a>
                <a
                  rel="noopener noreferrer"
                  className = "fb_clr"
                  target="_blank"
                  href=""
                  label="Facebook"
                >
                <i className="fa fa-facebook-square" aria-hidden="true"></i>
                  {""}
                </a>
                <a 
                  className = "insta_clr"
                  rel="noopener noreferrer"
                  target="_blank"
                  href=""
                  label="Instagram"
                >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  {""}
                </a>
                <a
                  className = "youtube_clr"
                  rel="noopener noreferrer"
                  target="_blank"
                  href=""
                  label="Youtube"
                >
                    <i className="fa fa-youtube-square" aria-hidden="true"></i>
                  {""}
                </a>
              </div>
              <p>© 2020 Net Pro Referral, Inc.</p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

FooterPage.propTypes = {};

FooterPage.defaultProps = {};

export default withRouter(FooterPage);
