import React, { Component } from "react";
import axios from "axios";
import Notify from "../../utils/AlertNotification";
import Loader from "../../utils/loader";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: localStorage.getItem("location") || "",
      searchText: localStorage.getItem("searchText") || "",
      locationList: [""],
      professionalList: [""],
      showProfessionalList: false,
      referralData: [{}],
      showCategory: false,
      loading: false,
      showError: ""
    };
  }

  getProfessionalList = async () => {
    const apiUrl =
      "https://8gsdr56bhd.execute-api.us-west-2.amazonaws.com/dev/getCategories";
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("An unknown error has occurred. Try again later.");
      }

      const data = await response.json();

      if (data.success === true) {
        this.setState({
          professionalList: [...data.Data],
          showProfessionalList: true,
        });
      }
    } catch (error) {
      Notify.sendNotification(error, "error");
    }
  };

  getCitiesList = async (value) => {
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error("An unknown error has occurred. Try again later.");
      }

      const data = await response.json();

      let validLocations = [];
      data.features.forEach((item) => {
        if (item.place_name.includes("United States")) {
          validLocations.push(item.place_name);
        }
      });

      this.setState({
        locationList: [...validLocations],
        showCategory: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  userSelect = (item, key) => {
    if (key === "searchText")
      this.setState({ searchText: item.item, showProfessionalList: false });
    else if (key === "location")
      this.setState({ location: item.item, showCategory: false });
  };

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
    if (key === "location") this.getCitiesList(e.target.value);
    else if (key === "searchText") this.getProfessionalList();
  };

  handleSubmit = (location, searchText) => {
    let isValid = false;
    for(let i = 0; i < this.state.professionalList.length; i++) {
      if(searchText === this.state.professionalList[i]) {
        isValid = true;
        this.setState({
          showError: "",
          loading: true
        });
      }
    }
    
    if(isValid === false) {
      this.setState({
        showError: "Select valid search input",
        loading: false
      });
      return 0;
    }

    if(!location || !searchText) {
      this.setState({
        showError: "Incorrect value",
        loading: false
      });
      return 0;
    } else {
      this.setState({
        showError: "",
        loading: true
      });
    }
    
    localStorage.setItem("location", location);
    localStorage.setItem("searchText", searchText);
    const apiUrl = `https://ict8dgsrsf.execute-api.us-west-2.amazonaws.com/development/getopensearchdata?location=${this.state.location}&category=${this.state.searchText}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.status === 200) {
          throw new Error("An unknown error has occurred. Try again later.");
        }

        if (response.data.statusCode === 200) {
          this.setState({
            referralData: [...response.data.body],
            loading: false
          });
        }
        const searchResult = {
          referralData: this.state.referralData,
          searchText: this.state.searchText,
          location: this.state.location,
        };

        this.props.getSearchData(searchResult);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { location, searchText } = this.state;
    return (
      <section className="form-block home-form-block ">
        <Loader props={this.state.loading}></Loader>
        <div className="wrapper">
          <div className="form-block1">
            <div className="search-block1 animated fadeInLeft">
              <div className="search-form">
                {/* <form> */}
                <div className="input-block-search">
                  <input
                    type="text"
                    title="What type of professional you need?"
                    placeholder="What type of professional you need?"
                    className="ui-autocomplete-input inputDashboard"
                    style={{ background: "#fff" }}
                    value={searchText}
                    onChange={(e) => this.handleChange(e, "searchText")}
                  />
                  {this.state.showProfessionalList && (
                    <ul
                      className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content"
                      style={{ marginTop: "45px", width: "272px" }}
                    >
                      {this.state.professionalList.map((item, index) => {
                        return (
                          <div>
                            {item
                              .toLowerCase()
                              .includes(this.state.searchText.toLowerCase()) && (
                              <li
                                key={index}
                                className="ui-menu-item"
                                onClick={(e) =>
                                  this.userSelect({ item }, "searchText")
                                }
                              >
                                {item}
                              </li>
                            )}
                          </div>
                        );
                      })}
                    </ul>
                  )}
                </div>
                <div className="input-block-search-loc">
                  <input
                    type="text"
                    title="Where?"
                    placeholder="Where?"
                    className="loc pac-target-input inputDashboard"
                    value={location}
                    onChange={(e) => this.handleChange(e, "location")}
                  />
                  {this.state.showCategory === true && (
                    <ul
                      className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content"
                      style={{ marginTop: "45px", width: "272px" }}
                    >
                      {this.state.locationList.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="ui-menu-item"
                            onClick={(e) =>
                              this.userSelect({ item }, "location")
                            }
                          >
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <img
                    alt="Location"
                    src="https://dcywhuojnzfz0.cloudfront.net/assets/images/loc.png"
                    className="loc-img initial loading"
                    data-was-processed="true"
                  />
                  <input type="hidden" name="userLoc" id="userLoc" />
                </div>
                <button
                  className="search-btn effect-main effect-color-hover"
                  onClick={(e) => this.handleSubmit(location, searchText)}
                >
                  Search
                </button>
                <small style={{color: 'red'}}>{this.state.showError}</small>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Search;
