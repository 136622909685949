import { Observable } from 'rxjs/Observable'
import { ajax as staticAjax } from 'rxjs/observable/dom/ajax'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/of'
import { apiCall } from '../../utils'
import { API_BASE_URL } from "../../common/index";
import { request } from "../../utils/ApiUtils";


export function postMessage(message) {
  return request({
      url: API_BASE_URL + '/api/message/send',
      method: 'POST',
      data: message
  });
}

export function getConversationsByUser(userId) {
  return request({
      url: API_BASE_URL + "/api/message/conversations/"+userId,
      method: 'GET'
  });
}

export function getMessagesByConversation(conversationId) {
  return request({
      url: API_BASE_URL + "/api/message/getall/"+conversationId,
      method: 'GET'
  });
}

export function deleteMessagesByUser(userId) {
  return request({
      url: API_BASE_URL + "/api/message/delete/"+userId,
      method: 'DELETE'
  });
}


