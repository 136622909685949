import React, { Component } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import { resetPassword} from "./service";
import Notify, { AlertTypes } from "../../utils/AlertNotification";
import { trackPromise } from 'react-promise-tracker';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},

        }
    }
    componentWillMount() { 
         
    }
    onChange = (e) => {

         ;
        const { name, value } = e.target;
        console.log(name, value);
        this.setState({
            [name]: value,
        });
    };

    handleValidation(fields) {
         ;
        let errors = {};
        let formIsValid = true;

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "NewPassword Cannot be empty";
        }

        if (!fields["confirmPassword"]) {
            formIsValid = false;
            errors["confirmPassword"] = "ConfirmPassword Cannot be empty";
        }

        if (typeof fields["password"] !== "undefined") {
            let filter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (filter.test(fields["password"])) {
                formIsValid = true;
                console.log("valid...........")
            } else {
                formIsValid = false;
                errors["password"] = "Password Must Contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
                console.log("notvalid...........")
            }

            if (!(fields["confirmPassword"] == fields["password"])) {
                formIsValid = false;
                errors["notMatch"] = "Password Not Match";
            }


        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    resetPassword = () => {
        let changePasswordRequest = {
            password: this.state.new_password,
            confirmPassword: this.state.re_password,
            passwordResetToken:this.props.match.params.token
        }
        if (this.handleValidation(changePasswordRequest)) {
            trackPromise(
            resetPassword(changePasswordRequest)
            .then((response) => {
               ;
              console.log(response);
             
              if ((response.data.status == "SUCCESS")) {
                 Notify.sendNotification(response.data.message, AlertTypes.success);
                 
                 //this.props.history.push("/profile");
              }
              if ((response.data.status == "FAIL")) {
                Notify.sendNotification(response.data.message, AlertTypes.error);
               
              }
            })
            .catch((error) => {
              if (error.status === 401) {
              } else {
              }
            })
            );
            
        }


    };

    render() {
        return (


            <div style={{
                backgroundImage: "url(" + "https://www.netproreferral.com/assets/images/banner1.jpg" + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '550px',
                width: '100%',
            }}>
                <div className="login-main1">
                    <div className="login-form-block login-blk-padd">
                        <div className="login-block forsuccerrMsg" style={{ display: 'none' }}>
                        </div>
                        <div className="welcome-block forsuccerrMsg">
                        </div>
                        <div className="welcome-block">
                            <h2>Welcome To <span> NET PRO</span> REFERRAL </h2>
                            <p>Please reset your password for your account.<br />You can use your email admin123@yopmail.com and this password to login into the system.</p>
                        </div>
                        <div className="login-block">
                            <div className="login-icon">
                                <div className="icon-img">
                                    <div className="icon-inner" />
                                </div>
                            </div>
                            <div className="set-form-custom">
                                <div className="login-form set-pw-form">
                                    <h2>Reset Password</h2>
                                    <div className="full-block">
                                        <div className="input-block">
                                            <input type="Password" className="key-icon reset-pw"
                                                title="New Password" placeholder="New Password"
                                                id="new_password"
                                                value={this.state.new_password || ""}
                                                onChange={this.onChange}
                                                name="new_password"
                                            />
                                            <span style={{position: "initial"}} className="error">{this.state.errors["newPassword"]}</span>

                                        </div>

                                    </div>
                                    <div className="full-block">
                                        <div className="input-block">
                                            <input type="Password" className="key-icon" title="Confirm Password"
                                                placeholder="Confirm Password"
                                                id="re_password"
                                                title="Confirm your new password"
                                                name="re_password"
                                                value={this.state.re_password || ""}
                                                onChange={this.onChange}
                                            />
                                            <span style={{position: "initial"}} className="error">{this.state.errors["notMatch"]}</span>

                                        </div>
                                    </div>
                                    <div className="full-block">
                                        <div className="input-block">
                                            <div className="login-lock"><button type="Submit" onClick={this.resetPassword} className="login-btn input-effect" name="forget-btn" value="Reset Password">Reset Password</button></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="forgot-links">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        );
    }
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default withRouter(ForgotPassword);
