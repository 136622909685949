import React, { Component } from "react";
import Header from "../header";
import FooterPage from "../footer";
import Routes from "../Routes/Routes";

class Main extends Component {
  render() {
    return (
      <div>
        <Header />
        <Routes />
        <FooterPage />
      </div>
    );
  }
}

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
