import React, { Component, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import ProfileModal from "./profileModal";

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      profileId: localStorage.getItem("id"),
      profileDetails: [""],
      userEmail: localStorage.getItem("email"),
      showModal: false,
      modalData: "",
    };
  }

  getUserProfile = async () => {
    const apiUrl = `https://ict8dgsrsf.execute-api.us-west-2.amazonaws.com/development/search/id=${this.state.profileId}`;

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error("Some server error has occurred");
      }

      const data = await response.json();

      if (data.statusCode === 200) {
        localStorage.setItem(
          "userImage",
          data.data.hits.hits[0]._source.images[0]
        );
        localStorage.setItem(
          "profession",
          data.data.hits.hits[0]._source.profession
        );
        localStorage.setItem(
          "firstname",
          data.data.hits.hits[0]._source.name.split(' ')[0]
        );
        localStorage.setItem(
          "lastname",
          data.data.hits.hits[0]._source.name.split(' ')[1]
        );
        this.setState({
          profileDetails: data.data.hits.hits[0]._source,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getUserProfile();
  }

  displayModal = (data) => {
    this.setState({
      showModal: true,
      modalData: data,
    });
  };

  closeModal = (close) => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <Fragment>
        <div className="basic-info">
          <div className="basic-info-heading">
            <h1>Basic information</h1>
          </div>
          <div className="edit-icon">
            <a onClick={() => this.displayModal("Basic Information")}>
              <img
                alt=""
                data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                className="initial loaded"
                src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                data-was-processed="true"
              />
            </a>
          </div>
          <p>
            {this.state.profileDetails.aboutMe
              ? this.state.profileDetails.aboutMe
              : "-"}
          </p>
        </div>
        <div className="basic-info">
          <div className="basic-info-heading">
            <h1>Additional information</h1>
          </div>
          <div className="info-detail">
            <div className="info-detail-left">
              <div className="detail-head">Address</div>
              <div className="detail-content">
                <div className="edit-icon">
                  <a
                    onClick={() => {
                      this.displayModal("Address");
                    }}
                  >
                    <img
                      alt=""
                      data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                      className="initial loaded"
                      src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                      data-was-processed="true"
                    />
                  </a>
                </div>
                {this.state.profileDetails.address ? (
                  <p>
                    {this.state.profileDetails.address}
                    <br />
                    {this.state.profileDetails.state_province &&
                      this.state.profileDetails.state_province +
                        ", " +
                        this.state.profileDetails.country +
                        ", " +
                        this.state.profileDetails.zipCode}
                  </p>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="info-detail-left">
              <div className="detail-head">Phone Number</div>
              <div className="detail-content">
                <div className="edit-icon">
                  <a
                    onClick={() => {
                      this.displayModal("Phone Number");
                    }}
                  >
                    <img
                      alt=""
                      data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                      className="initial loaded"
                      src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                      data-was-processed="true"
                    />
                  </a>
                </div>
                <p>
                  {this.state.profileDetails.phoneNumber
                    ? this.state.profileDetails.phoneNumber
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="info-detail">
            <div className="info-detail-left">
              <div className="detail-head">Occupation</div>
              <div className="detail-content">
                <div className="edit-icon">
                  <a
                    onClick={() => {
                      this.displayModal("Occupation");
                    }}
                  >
                    <img
                      alt=""
                      data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                      className="initial loaded"
                      src="https://dcywhuojnzfz0.cloudfront.net/assets/images/edit.png"
                      data-was-processed="true"
                    />
                  </a>
                </div>
                <p>{this.state.profileDetails.profession}</p>
              </div>
            </div>
            <div className="info-detail-left">
              <div className="detail-head">Email Address</div>
              <div className="detail-content">
                <p>
                  <a>{this.state.userEmail}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <ProfileModal
            openModal={this.state.showModal}
            modalData={this.state.modalData}
            onCloseModal={this.closeModal}
          ></ProfileModal>
        )}
      </Fragment>
    );
  }
}

Profile.propTypes = {};

Profile.defaultProps = {};

export default withRouter(Profile);
