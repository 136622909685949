import React, { Component, useRef, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import { TabList, Tab, TabPanel, Tabs } from "react-tabs";
import ReactModal from "react-modal";
import Notify, { AlertTypes } from "../../utils/AlertNotification";
import { getInvitationByToken, getUserById,getReferenceById } from "./referenceService";
import { submitReferral,editReferral } from "./service";



class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Index: 0,
      showModal: false,
      allCheck: false,
      referencelModel: false,
      submitReferralModal: false,
      errors: {},
      data: [],
      temp: [],
      check: false,

    };
    this.state.referralUser = {};
    this.state.invetation = {};
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.OpenReferencelModel = this.OpenReferencelModel.bind(this);
    this.closeReferencelModel = this.closeReferencelModel.bind(this);
    // this.state.message;
    this.submitReferralModal = this.submitReferralModal.bind(this);
    this.closeSubmitReferralModal = this.closeSubmitReferralModal.bind(this);



  }
  OpenReferencelModel() {
    this.setState({ referencelModel: true });
  }

  closeReferencelModel() {
    this.setState({ referencelModel: false });
  }

  submitReferralModal() {
    this.setState({ submitReferralModal: true });
  }

  closeSubmitReferralModal() {
    this.setState({ submitReferralModal: false });
  }

  componentWillMount() {

    getReferenceById(this.props.computedMatch.params.token)
      .then((response) => {
        console.log(response);
        if ((response.data.status == "SUCCESS")) {
          this.setState({ invetation: response.data.data })
          this.setState({message:response.data.data.message})
          getUserById(response.data.data.referralUserId)
            .then((response) => {
              console.log(response);
              if ((response.data.status == "SUCCESS")) {
                this.setState({ referralUser: response.data.data })
              }
              if ((response.data.status == "FAIL")) {

              }
            })
        }
        if ((response.data.status == "FAIL")) {

        }
      })
    // const names = ["James", "Paul", "John", "George", "Ringo"];
    //this.setState({ data: names })
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }


  handleOpenModal = () => {
     ;
    this.setState({ showModal: true });
  };
  onChange = (e) => {
    this.setState({ message: e.target.value });
    console.log(this.state.message)
  };

  updateReferrance = () => {
     
    let obj = this.state.invetation
     obj.message=this.state.message;
     editReferral(obj)
      .then((response) => {
         ;
        console.log(response);

        if ((response.data.status == "SUCCESS")) {
          Notify.sendNotification(response.data.message, AlertTypes.success);

          this.props.history.push("/dashboard#/referralList");
        }
        if ((response.data.status == "FAIL")) {
          Notify.sendNotification(response.data.message, AlertTypes.error);

        }
      })
      .catch((error) => {
        if (error.status === 401) {
        } else {
        }
      });

  }
  render() {
    return (
      <div >
        <section className="listing-detail-header">
          <div className="wrapper">
            <div className="profile-user ">
              <div className="profile-pic-main1">
                <div className="profile-piv-left">
                  <img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.png" className="initial loading" data-was-processed="true" />					</div>
                <div className="detail-pro-page">
                  <h3>{this.state.referralUser.firstName}  {this.state.referralUser.lastName} </h3>
                  <ul>
                    <li className="pro-law pro-tag-line">{this.state.referralUser.tagLine}</li>
                    <li className="pro-law">{this.state.referralUser.profession}</li>
                    <li className="pro-tel">999999999</li>
                    <li className="pro-web"><a rel="nofollow noopener" target="_blank" style={{ color: 'rgb(0, 175, 235)' }} href="http://www.example.com">http://www.example.com</a></li>
                    <li className="pro-add">pune maharastra test, Devoll  41102</li>
                    <li>
                      <p className="advertise-social">
                        <a title="Facebook" className="fb-adver" onclick="sharePop('Facebook','https://www.netproreferral.com/m/vijay-vijay-ee35e8ea7f7d6969180bf29f38abaf83');" href="javascript:void(0)" />&nbsp;
                      <a title="Linkedin" className="lin-adver" onclick="sharePop('Linkedin','https://www.netproreferral.com/m/vijay-vijay-ee35e8ea7f7d6969180bf29f38abaf83');" href="javascript:void(0)" />&nbsp;
                      <a title="Twitter" className="twi-adver" onclick="sharePop('Twitter','https://www.netproreferral.com/m/vijay-vijay-ee35e8ea7f7d6969180bf29f38abaf83');" href="javascript:void(0)" />&nbsp;
                      <a title="Google" className="goggle-adver" onclick="sharePop('Google','https://www.netproreferral.com/m/vijay-vijay-ee35e8ea7f7d6969180bf29f38abaf83');" href="javascript:void(0)" />&nbsp;
                    </p>
                    </li>
                  </ul>
                  <a onClick={this.handleOpenModal} className="effect-main effect-contact-hover" href="javascript:void(0)" id="SendNPRmail">Send Message</a>{/* echo $firstName; */}
                  <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    className="Modal"
                    overlayClassName="Overlay"
                    onRequestClose={this.handleCloseModal}
                  >
                    <div id="send_msg_pop_up" className="message-overlay" style={{ display: 'block' }}>
                      <div className="message-tb">
                        <div className="message-tbcell">
                          <div className="message-main-content">
                            <div className="pop_cross">
                              <a onclick="closeVsendMsgForm();" className="cross"><img alt="Close" onClick={this.handleCloseModal} src="https://dcywhuojnzfz0.cloudfront.net/assets/images/multiply.png" className="initial loading" data-was-processed="true" /></a>
                            </div>
                            <div className="message-top-heading">
                              <h2><span id="sendText">Send Message to {this.state.referralUser.firstName}</span></h2>
                            </div>
                            <div className="message-main-form">
                              <form method="POST" action="https://www.netproreferral.com/home/sendMessage" id="vsendMessage" name="vsendMessage" noValidate="novalidate">
                                <div className="message-error-cover">
                                  <input type="text" name="subject" id="subject" placeholder="Subject" aria-required="true" />
                                  {/*p id="content-err-msg-subject" style="font-size: 12px; color: rgb(255, 0, 0);">Please enter subject.</p*/}
                                </div>
                                <div className="message-error-cover">
                                  <textarea name="content" id="content" placeholder="Message" aria-required="true" defaultValue={""} />
                                  {/*p id="content-err-msg-content" style="font-size: 12px; color: rgb(255, 0, 0);">Please enter message.</p*/}
                                </div>
                                <button className="effect-main effect-color-hover" type="submit">Send Message</button>{/* onclick="sendMessage();" */}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ReactModal>
                </div>
                <div className="referal-block">
                  <div className="referal-block-inner">
                    <div className="referal-block-inner-main">
                      <h2>referrals</h2>
                      <div className="referal-count"><span>0</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="wrapper">

          <section className="form-block my-profile-list detail-prof-view">
            <div className="conatct-form-main">
              <div className="edu-tab-outer">
                <div id="tabs-container" className="edu-tab-inner">
                  <input type="hidden" defaultValue id="invitedUser" />
                  <Tabs selectedIndex={this.state.Index} onSelect={Index => this.setState({ Index })}>
                    <TabList>
                      <input type="hidden" defaultValue id="invitedUser" />
                      <ul className="tabs-menu edu-tabList">
                        <Tab>
                          <li className={[(this.state.Index == 0 ? 'current' : '')]}><a className="tab-2">Contact Information</a></li>
                        </Tab>
                        <Tab>
                          <li className={[(this.state.Index == 1 ? 'current' : '')]}><a className="tab-3">Education</a></li>
                        </Tab>
                        <Tab>
                          <li className={[(this.state.Index == 2 ? 'current' : '')]}><a className="tab-4">Skills &amp; Experience</a></li>
                        </Tab>
                        <Tab>
                          <li className={[(this.state.Index == 3 ? 'current' : '')]}><a className="login2refer">Refer {this.state.referralUser.firstName} </a></li>
                        </Tab>
                      </ul>
                    </TabList>
                    <div className="tab">
                      <TabPanel>
                        <div id="tab-2" style={{ display: 'block' }} className="tab-content edu-tab-contant refertabcontent">
                          <ul>
                            <li className="edu-tab-inr-div1">
                              <h4> Country </h4>
                              <h5> Albania </h5>
                            </li>
                            <li className="edu-tab-inr-div1 edu-tab-inr-div2">
                              <h4> State </h4>
                              <h5>Devoll </h5>
                            </li>
                            <li className="edu-tab-inr-div1">
                              <h4> City </h4>
                              <h5> test </h5>
                            </li>
                            <li className="edu-tab-inr-div1 edu-tab-inr-div2">
                              <h4> Zip Code </h4>
                              <h5> 41102 </h5>
                            </li>
                            <li className="edu-tab-inr-div1">
                              <h4> Business Phone 1. </h4>
                              <h5>+91 (956) 565-6577</h5>					        			</li>
                            <li className="edu-tab-inr-div1">
                              <h4> Membership </h4>
                              <h5> primium </h5>
                            </li>
                            <li className="edu-tab-inr-div1 edu-tab-inr-div2">
                              <h4> Interest and Hobbies </h4>
                              <h5> swimming,reading </h5>
                            </li>
                            <li>
                              <div className="edu-tab-inr-full">
                                <h4> Activities and Societies </h4>
                                <h5>
                                  cricket
                        </h5>
                              </div>
                            </li>
                            <li>
                              <div className="edu-tab-inr-full">
                                <h4> Statement </h4>
                                <h5>
                                  good is goos						        				</h5>
                              </div>
                            </li>
                            <li>
                              <div className="edu-tab-inr-full">
                                <h4> Language(s) </h4>
                                <div style={{ display: 'block' }}>
                                  <h5 style={{ background: 'none', cursor: 'text' }}>Bahamas Creole English (Beginner)</h5>
                                </div>						        			</div>
                            </li>
                          </ul>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div id="tab-2" style={{ display: 'block' }} className="tab-content edu-tab-contant">

                          <ul className="edu-tab3">
                            <li>
                              <div className="edu-tab-inr-full">
                                <h5>High School in 1950 from test (test)</h5>
                              </div>
                            </li>
                            <li>
                              <div className="edu-tab-inr-full">
                                <h5>Under Graduate in 1953 from dssfasdfds (dsfadsf)</h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div id="tab-4" style={{ display: 'block' }} className="tab-content edu-tab-contant">
                          <div className="skill-outer">
                            <h3> Skills </h3>
                            <ul>
                              <li><p> <span style={{ backgroundColor: '#fff', padding: '2px 0px' }} /><span className="new-span">Java</span></p></li><li><p> <span style={{ backgroundColor: '#fff', padding: '2px 0px' }} /><span className="new-span">Springs-Wholesale And Manufacturers</span></p></li>
                            </ul>
                          </div>
                          <div className="skill-outer skill-prof-page">
                            <ul className="edu-tab3">
                              <li>
                                <h3> Patents </h3>
                                <div className="edu-tab-inr-full">
                                  <h5> dsafdsf </h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="skill-outer skill-prof-page">
                            <ul className="edu-tab3">
                              <li>
                                <h3> Publications </h3>
                                <div className="edu-tab-inr-full">
                                  <h5></h5>
                                  <div className="pub-expend-inner pubDiv202">
                                    <h3 className="pub-expend-title">surya</h3>
                                    <div className="pub-expend-date">Nov 18, 1969 - tester</div>
                                    <div className="pub-expend-text">
                                    </div>
                                    <div className="contributors-outer">
                                    </div>
                                    <div className="pubpublication">
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div id="tab-5" className="tab-content edu-tab-contant" style={{ display: 'block' }}>
                          <input type="hidden" name="endorse_by" defaultValue={1635520} />
                          <input type="hidden" name="endorse_to" defaultValue={1635528} />
                          <div className="text_field">
                            <label>Write your reference for {this.state.referralUser.firstName} </label>
                            <div className="texr_rerro">
                              <textarea
                                style={{ fontSize: '13px', height: '90px', overflow: 'auto' }}
                                name="message"
                                id="message"
                                value={this.state.message}
                                onChange={this.onChange}

                              />
                              <span className="error" />
                            </div>
                          </div>
                          <div className="endorse skill-outer">
                            <h3>Endorse Skills</h3>
                            <ul className="enorseClass">
                              <li><span>Java</span> <a title="Endorse for Java" className="checkli_end_j checkli  id_20 checkli_end"><label style={{ cursor: 'pointer' }}><img alt="" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/dot-Circle.png" /></label></a></li><input type="checkbox" className="check_20" name="skill_id[]" defaultValue={20} style={{ display: 'none' }} /><li><span>Springs-Wholesale And Manufacturers</span> <a title="Endorse for Springs-Wholesale And Manufacturers" className="checkli_end_j checkli  id_2919 checkli_end"><label style={{ cursor: 'pointer' }}><img alt="" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/dot-Circle.png" /></label></a></li><input type="checkbox" className="check_2919" name="skill_id[]" defaultValue={2919} style={{ display: 'none' }} />								 </ul>
                          </div>
                          <div className="submit_butn refer-sub-btn">
                            <button type="Submit" onClick={this.updateReferrance} value="Submit" className="login-btn btn-main-link active effect-main effect-color-hover">Submit</button>
                            <a onClick={this.handleOpenModal} className="effect-main effect-ask-color-hover" id="askReferral" href="javascript:void(0)">Ask vijay to refer you.
                              </a>
                          </div>
                        </div>
                        <ReactModal
                          isOpen={this.state.showModal}
                          contentLabel="Minimal Modal Example"
                          className="Modal"
                          overlayClassName="Overlay"
                          onRequestClose={this.handleCloseModal}
                        >
                          <div className="more_referral_popup_prof_cfr" id="more_referral_popup_askRef" style={{ display: 'block' }}>
                            <div className="content stud_pop_main content-vert">
                              <div className="pop_cross">
                                <a className="cross"><img onClick={this.handleCloseModal} alt="Close" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/multiply.png" className="loading" data-was-processed="true" /></a>
                              </div>
                              <div className="stud_pop_head">
                                <span className="head-email-noti">Referral Request Sent</span>
                              </div>
                              <p className="validateTips" />
                              <div className="basic-info">
                                <label className="email-noti-msg">We have notified vijay to refer you. Meanwhile, get referred from your other contacts/friends.</label>
                              </div>
                              <div className="pop_up_rigt_div get-more-pop-btn">
                                <button style={{ marginTop: '15px', background: 'rgb(148, 161, 176) none repeat scroll 0 0' }} className="btn-main-link active cross effect-main effect-ok-hover">OK</button>
                                <button onclick="location.href='https://www.netproreferral.com/user/sendinvite';" className="btn-main-link active effect-main effect-color-hover">Get More Referrals</button>
                              </div>
                            </div>
                          </div>
                        </ReactModal>

                        <ReactModal
                          isOpen={this.state.referencelModel}
                          contentLabel="Minimal Modal Example"
                          className="Modal"
                          overlayClassName="Overlay"
                          onRequestClose={this.closeReferencelModel}
                        >
                          <div>
                            <div className="sweet-alert " data-custom-class data-has-cancel-button="true" data-has-confirm-button="true" data-allow-outside-click="false" data-has-done-function="true" data-animation="pop" data-timer="null" style={{ display: 'block', marginTop: '-185px' }}><div className="sa-icon sa-error" style={{ display: 'none' }}>
                              <span className="sa-x-mark">
                                <span className="sa-line sa-left" />
                                <span className="sa-line sa-right" />
                              </span>
                            </div><div className="sa-icon sa-warning" style={{ display: 'none' }}>
                                <span className="sa-body" />
                                <span className="sa-dot" />
                              </div><div className="sa-icon sa-info" style={{ display: 'none' }} /><div className="sa-icon sa-success animate" style={{ display: 'block' }}>
                                <span className="sa-line sa-tip animateSuccessTip" />
                                <span className="sa-line sa-long animateSuccessLong" />
                                <div className="sa-placeholder" />
                                <div className="sa-fix" />
                              </div><div className="sa-icon sa-custom" style={{ display: 'none' }} /><h2>You have successfully given referral to Vijay Vijay. Now complete your profile to rank high in search results.</h2>
                              <p />
                              <fieldset>
                                <input type="text" tabIndex={3} placeholder />
                                <div className="sa-input-error" />
                              </fieldset><div className="sa-error-container">
                                <div className="icon">!</div>
                                <p>Not valid!</p>
                              </div><div className="sa-button-container">
                                <button className="cancel" tabIndex={2} style={{ display: 'inline-block' }}>Cancel</button>
                                <div className="sa-confirm-button-container">
                                  <button className="confirm" tabIndex={1} style={{ display: 'inline-block', backgroundColor: 'rgb(45, 175, 235)', boxShadow: 'rgba(45, 175, 235, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset' }}>Complete Profile</button><div className="la-ball-fall">
                                    <div />
                                    <div />
                                    <div />
                                  </div>
                                </div>
                              </div></div>
                          </div>
                        </ReactModal>
                      </TabPanel>
                    </div>

                  </Tabs>
                  <ReactModal
                    isOpen={this.state.submitReferralModal}
                    contentLabel="Minimal Modal Example"
                    className="Modal"
                    overlayClassName="Overlay"
                    onRequestClose={this.closeSubmitReferralModal}
                  >
                    <div className="sweet-alert showSweetAlert visible" data-custom-class data-has-cancel-button="true" data-has-confirm-button="true" data-allow-outside-click="false" data-has-done-function="true" data-animation="pop" data-timer="null" style={{ display: 'block', marginTop: '-185px' }}><div className="sa-icon sa-error" style={{ display: 'none' }}>
                      <span className="sa-x-mark">
                        <span className="sa-line sa-left" />
                        <span className="sa-line sa-right" />
                      </span>
                    </div><div className="sa-icon sa-warning" style={{ display: 'none' }}>
                        <span className="sa-body" />
                        <span className="sa-dot" />
                      </div><div className="sa-icon sa-info" style={{ display: 'none' }} /><div className="sa-icon sa-success animate" style={{ display: 'block' }}>
                        <span className="sa-line sa-tip animateSuccessTip" />
                        <span className="sa-line sa-long animateSuccessLong" />
                        <div className="sa-placeholder" />
                        <div className="sa-fix" />
                      </div><div className="sa-icon sa-custom" style={{ display: 'none' }} /><h2>You have successfully given referral to Ajit M. Now complete your profile to rank high in search results.</h2>
                      <p />
                      <fieldset>
                        <input type="text" tabIndex={3} placeholder />
                        <div className="sa-input-error" />
                      </fieldset><div className="sa-error-container">
                        <div className="icon">!</div>
                        <p>Not valid!</p>
                      </div><div className="sa-button-container">
                        <button className="cancel" tabIndex={2} style={{ display: 'inline-block' }}>Cancel</button>
                        <div className="sa-confirm-button-container">
                          <button className="confirm" tabIndex={1} style={{ display: 'inline-block', backgroundColor: 'rgb(45, 175, 235)', boxShadow: 'rgba(45, 175, 235, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset' }}>Complete Profile</button><div className="la-ball-fall">
                            <div />
                            <div />
                            <div />
                          </div>
                        </div>
                      </div></div>
                  </ReactModal>
                </div>
              </div>
              <div className="pro-user-review">
                <h1 className="pro-recomend">References</h1>
                <p>No reference found</p>
                {/*a class="view-recomend">View More Recommendations</a*/}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

Referral.propTypes = {};

Referral.defaultProps = {};

export default withRouter(Referral);
