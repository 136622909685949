import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
export const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&
        <div>
            <div className='loading-data' id="overlay">
                <img src='https://www.netproreferral.com/assets/images/loader.svg' />
            </div>
        </div>
    );
}
