import React from "react";
import ReactDOM from 'react-dom';

function Loader(props) {
  if (props.props == true) {
    return (
      <div>
        <div className='loading-data' id="overlay">
          <img src='https://www.netproreferral.com/assets/images/loader.svg' />
        </div>
      </div>
    );
  } else {
    return (
      <div>
      </div>
    );
  }
}
export default Loader;