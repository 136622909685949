import React, { Component, useRef, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import NewComponent from "./addUser";
import ReactModal from "react-modal";
import { sendInventation } from "./refferredService";
import Notify, { AlertTypes } from "../../utils/AlertNotification";
import { trackPromise } from 'react-promise-tracker';
import { sweetAlert } from '../../components/Alert/Alert';

class Referred extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,

      allCheck: false,
      errors: {},
      data: [],
      temp: [],
      check: false,
      isValidError: ''
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.NewComponent = React.createRef();
    this.state.edit_email_value =
      "Dear { NAME } \n\nI am requesting that you refer me to online searchers on NetProReferral.com.\n\nIt is NetProReferral.com where you can connect with fellow professional experts, peers, influencers, and trendsetters to get referrals from them. Besides, you too can refer other experts whom you trust and value.\n\nIt is the only place on the web where you can build your professional image with utmost authenticity because it is fully based on genuine expert referrals. No more fear and hassle of fake and biased reviews!\n\nIf you are already there in the NetProReferral database then you know what I am talking about, right? You may just log in to your account and visit my profile and write me a referral to let me know that you are there. I will refer you back!\n\nAnd in case you do not have any account yet, then you are missing out a sure shot opportunity of professional presence development online with NetProReferral.com.\n\nSign up right away to find me there, to refer me, to get referred and to know much more!";
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  callbackFunction = (childData) => {
    const referList = [];
    childData.map(function (data) {
      let temp = {
        ref_email: data.ref_email,
        ref_name: data.ref_name,
        isChecked: false,
      };
      referList.push(temp);
    });
    this.setState({ data: referList });
  };

  handleOpenModal = () => {
    if(this.state.data.length === 0) {
      this.setState({
        isValidError: "Fields cannot be empty"
      });
      return 0;
    } else {
      this.setState({
        isValidError: ''
      });
    }
    this.setState({ showModal: true });
  };
  onChange = (e) => {
     ;
    this.setState({
      edit_email_value: e.target.defaultValue,
    });
  };

  selectReferral = (value) => {
    var isCheck = false;
    var count = 0;
    var temp = this.state.data.length;
    this.state.data.map(function (data) {
      if (data.ref_email == value.ref_email) {
        if (!data.isChecked) {
          data.isChecked = true;
        } else {
          data.isChecked = false;
        }
      }
      if (data.isChecked) {
        count++;
      }
      if (temp == count) {
        isCheck = false;
      } else {
        isCheck = true;
      }
    });
    if (isCheck) {
      this.setState({ allCheck: false });
    } else {
      this.setState({ allCheck: true });
    }
    this.setState({ data: this.state.data });
  };

  sendInventation = async () => {
    var inventations = [];
    var message = this.state.edit_email_value;
    this.state.data.map(function (data) {
      if (data.isChecked) {
        var inventation = {
          "email": data.ref_email,
          "messageBody": message.replace("NAME", data.ref_name)
        }
        inventations.push(inventation);
      }
    });
    inventations = {
      "id": localStorage.getItem("id"),
      "emailList": [...inventations]
    }

    const apiUrl = "https://8gsdr56bhd.execute-api.us-west-2.amazonaws.com/dev/GetReffered";
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(inventations)
      });

      if(!response.ok) {
        throw new Error("Some server error has occurred");
      }

      const data = await response.json();
      if(data.success) {
        this.setState({showModal: false});
        this.props.history.push('/profile');
        sweetAlert(data.message, 'success', false);
      }
    } catch (error) {
        Notify.sendNotification(error, 'error');
    }
     
  };

  toggleChange = () => {
    this.setState({ allCheck: !this.state.allCheck });
    if (!this.state.allCheck) {
      this.state.data.map(function (data) {
        data.isChecked = true;
      });
    } else {
      this.state.data.map(function (data) {
        data.isChecked = false;
      });
    }
    this.setState({ data: this.state.data });
  };

  render() {
    return (
      <div className="basic-info">

        <div className="tag-heading ref-top-text">
          <p>
            Please enter name and email address of people who you want to invite
            to refer you.
          </p>
        </div>
        <div className="get-ref-cover">
          <div className="tag-input-block">
            <NewComponent
              props={this.state}
              parentCallback={this.callbackFunction}
            ></NewComponent>
            <div>
              <input
                style={{ opacity: 0 }}
                type="checkbox"
                name="new-content"
                id="new-content"
              />
              <small className="error">{this.state.isValidError}</small>
              <br />
              <label
                style={{
                  paddingTop: "10px",
                  marginLeft: "-15px",
                  float: "left",
                  fontSize: "12px",
                }}
                className="del app-it"
                htmlFor="new-content"
              >
                Customize invitation message
              </label>
              <div
                className="profile-input-block bussineAdres"
                style={{}}
                id="content-div"
              >
                <textarea
                  title="Customize invitation message"
                  style={{
                    height: "200px",
                    fontSize: "13px",
                    outline: "none",
                  }}
                  className="email-edit-content"
                  id="edit_email_value"
                  name="edit_email_value"
                  value={this.state.edit_email_value}
                  onChange={this.onChange}
                />
                <span
                  className="emailCntnt"
                  style={{
                    float: "left",
                    display: "none",
                    color: "red",
                    fontSize: "12px",
                  }}
                />
              </div>
            </div>
            <div className="btn-block ref-inv-btn">
              <button
                onClick={this.handleOpenModal}
                className="btn-main-link active effect-main effect-color-hover"
                id="sendInvites"
              >
                <span>Get Referred</span>
                <img
                  alt="NetProReferral"
                  src="https://dcywhuojnzfz0.cloudfront.net/assets/images/send.png"
                  className="initial loading"
                  data-was-processed="true"
                />
              </button>
            </div>
            <ReactModal
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              className="Modal"
              overlayClassName="Overlay"
              onRequestClose={this.handleCloseModal}
            >
              <div
                className="more_referral_popup_notinDB"
                id="more_referral_popup_notinDB"
                style={{ display: "block" }}
              >
                <div className="content stud_pop_main content-vert">
                  <div className="pop_cross">
                    <a onClick={this.handleCloseModal} className="cross">
                      <img
                        alt="NetProReferral"
                        src="https://dcywhuojnzfz0.cloudfront.net/assets/images/multiply.png"
                        className="loading"
                        data-was-processed="true"
                      />
                    </a>
                  </div>
                  <div className="stud_pop_head">
                    <span>Please Note</span>
                  </div>
                  <p className="validateTips" />
                  <div className="basic-info">
                    <label>
                      Follwoing members does not exist in our database. Do you
                      authorize sending email to them ?
                    </label>
                  </div>
                  <span className="ref-select-all-not-db">
                    <input
                      type="checkbox"
                      value="All"
                      id="selectAllNotDB"
                      checked={this.state.allCheck}
                      onClick={() => this.toggleChange("All")}
                    />
                    {this.state.allCheck}
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor="selectAllNotDB"
                    >
                      Select All
                    </label>
                    <span
                      id="err-msg-contact-import-no-db"
                      style={{
                        float: "right",
                        fontSize: "12px",
                        color: "red",
                        display: "none",
                      }}
                    >
                      Please select contacts.
                    </span>
                  </span>

                  {this.state.data.map((x, i) => {
                     ;
                    return (
                      <div>
                        {this.state.data[i].ref_name && (
                          <ul className="not-db-email-listing">
                            <li>
                              <input
                                className="checkboxes"
                                type="checkbox"
                                id={x.ref_email}
                                checked={x.isChecked}
                                onClick={() => this.selectReferral(x)}
                              />
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor={x.ref_email}
                              >
                                {x.ref_name} ({x.ref_email})
                          </label>
                            </li>
                          </ul>
                        )}
                      </div>
                    );
                  })}

                  <div className="pop_up_rigt_div">
                    <div className="not-in-db-msg">
                      <span>
                        Please select emails to which referral request will be
                        sent.
                      </span>
                    </div>
                    <button
                      style={{ marginTop: "23px", marginRight: "10px" }}
                      className="btn-main-link active effect-main effect-color-hover"
                      id="yes"
                      onClick={this.sendInventation}
                    >
                      Yes
                    </button>
                    <button
                      style={{
                        background: "rgb(148, 161, 176) none repeat scroll 0 0",
                      }}
                      className="btn-main-link active effect-main effect-ok-hover"
                      id="no"
                      onClick={this.handleCloseModal}
                    >
                      No
                    </button>
                    <input id="email_auth" type="hidden" defaultValue />
                  </div>
                </div>
              </div>

              <button onClick={this.handleCloseModal}>Close Modal</button>
            </ReactModal>
          </div>
          <div className="invite-btns">
            {/*
         <span title="Invite Google Contacts" id="gContacts"><span class="gContacts-icon"><img alt="NetProReferral" src="< ?php echo $this->config->item('cdn_url'); ?>assets/images/google-icon-btnpop.png"></span>Invite with Google </span>
         */}
            <span title="Invite Yahoo Contacts" id="yContacts">
              <span className="gContacts-icon">
                <img
                  alt="NetProReferral"
                  src="https://dcywhuojnzfz0.cloudfront.net/assets/images/yahoo-iconpop.png"
                  className="initial loading"
                  data-was-processed="true"
                />
              </span>
              Invite with Yahoo{" "}
            </span>
            <span title="Invite Outlook Contacts" id="msContacts">
              <span className="gContacts-icon">
                <img
                  alt="NetProReferral"
                  src="https://dcywhuojnzfz0.cloudfront.net/assets/images/outlook.png"
                  className="initial loading"
                  data-was-processed="true"
                />
              </span>
              Invite with Outlook{" "}
            </span>
            <a className="invite-or-circle" href="javascript:void(0);">
              or
            </a>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

Referred.propTypes = {};

Referred.defaultProps = {};

export default withRouter(Referred);
