import React, { Component, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter, NavLink } from "react-router-dom";
import { TabList, Tab, TabPanel, Tabs } from "react-tabs";
import * as moment from 'moment'
import ReactModal from "react-modal";
import { getRequestedInvetation, getRecivedRefefrral,
         getRefeeralGiven, getRefferalRequests,deleteReferance } from "./referralListService";
import swal from 'sweetalert';
import Notify, { AlertTypes } from "../../utils/AlertNotification";

class ReferralsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Index: 0,
      showModal: false,
      showEmainSendModel: false,
      requestedReferral: [],
      receivedReferral: [],
      givenReferral: [],
      requestReferral: [],
      requestedReferralData: []
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.OpenEmainSendModel = this.OpenEmainSendModel.bind(this);
    this.CloseEmainSendModel = this.CloseEmainSendModel.bind(this);
    this.viewReferenceMessage = this.viewReferenceMessage.bind(this);
    //this.handleOpenModal1 = this.handleOpenModal1.bind(this);
    this.state.edit_email_value = "Hi \n\n I am abc abc (software engineer), from NetProReferral.com.\n\nThis is to inform you that I have requested a reference from you for my professional profile on NetProReferral professional network as I value your work as an expert professional and wish all the success for you!\n\n\nI just wanted to invite and remind you to refer me on NetProReferral.com."
    this.state.token = "sadfdsfdsfdsf";
    // this.state.referenceMessage;
  }

  OpenEmainSendModel() {
    this.setState({ showEmainSendModel: true });
  }
  
  CloseEmainSendModel() {
    this.setState({ showEmainSendModel: false });
  }

  handleOpenModal() {
     
    this.setState({ showModal: true });
  }

  viewReferenceMessage = (data) => {

    this.setState({ referenceMessage: data._targetInst.memoizedProps.value });
  }


  handleCloseModal() {
    this.setState({ showModal: false });
  }

  getRequestedInvetation = async () => {
    try {
      const apiUrl = `https://ict8dgsrsf.execute-api.us-west-2.amazonaws.com/development/search/id=${localStorage.getItem('id')}`;
      const response = await fetch(apiUrl);

      if(!response.ok) {
        throw new Error("Some server error occurred");
      }

      const data = await response.json();
      if(data.statusCode === 200) {
        this.setState({
          requestedReferral: data.data.hits.hits[0]._source.refList
        });

        const referralDataApi = `https://ict8dgsrsf.execute-api.us-west-2.amazonaws.com/development/search/id=${this.state.requestedReferral}`;
        const referralResponse = await fetch(referralDataApi);

        if(!referralResponse.ok) {
          throw new Error("Some server error has occurred");
        }

        const referralData = await referralResponse.json();
        if(referralData.statusCode === 200) {
          this.setState({
            requestedReferralData: [referralData.data.hits.hits[0]._source]
          });
        }
      } 
      
      else {
        this.setState({
          requestedReferral: 'No referrals found'
        });
      }
    } catch (error) {
      Notify.sendNotification(error, 'error');
    }
  };

  componentDidMount() {
    const yourDate = new Date("2020-09-05T05:23:26.059+0000")
    const NewDate = moment(yourDate).format('lll')
    this.getRequestedInvetation();
  }


  render() {
    return (
      <div className="basic-info">
        <Tabs selectedIndex={this.state.Index} onSelect={Index => this.setState({ Index })}>

          <div className="edit-info-step edit-info-line">
            <TabList>
              <Tab>
                <div className="edit-block1 ref-tab1">
                  <p><a id="ref_req" onClick={this.getRequestedInvetation} className={['clickTopbar ' + (this.state.Index == 0 ? 'active' : '')]} href="javascript:;">Referrals Details</a></p>
                </div>
              </Tab>
            </TabList>
          </div>

          <div className="dashboard-inner ref-tabs">
            {/* Referrals Requested */}
            <TabPanel>
              <div style={{ marginTop: 0 }} className="dash-table-div my_table_div ref-req-table">
                <table>
                  <thead>
                    <tr><th width="30px"> </th><th>Name</th><th>Phone Number</th><th className="dash-table-center">Profession</th></tr>
                  </thead>

                  {this.state.requestedReferralData.map((x, i) => {
                    return (
                      <tbody key={i}>
                        <tr>
                          <td>
                            <a href="https://www.netproreferral.com/m/edefea300ddc978aac095741f5667534">
                              <div className="list-user-img-bluck active"><img alt="NetProReferral" className="img-1-blk initial loaded" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.png" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.png" data-was-processed="true" /></div>
                            </a>
                          </td>
                          <td>{x.name}</td>
                          <td className="td-date-p">
                              {x.phoneNumber || '-'}  	<input type="hidden" defaultValue />
                          </td>
                          <td className="dash-table-center td-no-padding">
                            {x.profession || '-'}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </TabPanel>
            {/* Referrals Received */}
            </div>
        </Tabs>
      </div>
    );
  }
}

ReferralsList.propTypes = {};

ReferralsList.defaultProps = {};

export default withRouter(ReferralsList);
