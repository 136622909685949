import React, { Component, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import ReactModal from "react-modal"
import NewComponent from "./phoneVerificationModal";
import PublicationModal from "./addPublicationModal";
import { TabList, Tab, TabPanel, Tabs } from "react-tabs";
import countries from '../../common/countryWithState.json';
import countriesCodes from '../../common/countryCodes.json';
import moment from 'moment';
import { FORM_API_LIST } from "./formApiList";
import Notify from "../../utils/AlertNotification";
import { sweetAlert } from '../../components/Alert/Alert';
import "./index.css";

let contacts = [];
let states = [];
function generateArrayOfYears() {
	var max = new Date().getFullYear()
	var min = max - 100
	var years = []
  
	for (var i = max; i >= min; i--) {
	  years.push(i)
	}
	return years
  }
let educations = [];
class editprofile extends Component {
    constructor(props) {
        super(props);
        this.state = {
			showModal: false,
			isShowPublicationModal:false,
			Index: 0,
			educations:[],
			contacts:[],
			professionalSkills:[],
			expiriences:[],
			languages:[],
			publications:[],
			user_email: localStorage.getItem("email"),
			profession: localStorage.getItem("profession")
        }
    }

    onChange = (e, type) => {
		if(type === 'text') {
			const validateInput = /^[A-Za-z ,]+$/;
			if(e.target.value !== '') {
				if(!validateInput.test(e.target.value)) {
					return 0;
				}
			}
		}
        const { name, value } = e.target;
        if (name === 'country') {
            states = countries.countries.filter((itr) => itr.country === value)[0].states;
        }
        this.setState({
            [name]: value
        })
    };

    handleOpenModal = () => {
		let data = {phoneNoCode:this.state.phoneNoCode,phoneNo:this.state.phoneNo,phoneType:this.state.phoneType,status:'Not verified',isVerify:true};
		this.setState({contacts:[...this.state.contacts, data]});
        this.setState({ showModal: true });
    }
	addEductaion = () => {
		let data = {degree:this.state.degree,level:this.state.level,institution:this.state.institution,year:this.state.edu_year};
		this.setState({educations:[...this.state.educations, data]});
	}
	addProfessionalSkills = (event) => {
		if (event.key === "Enter") {
			let data = {professionalSkill:this.state.professionalSkill};
			this.setState({professionalSkills:[...this.state.professionalSkills, data],professionalSkill:''});
		}
	}
	diff_months = (dt2, dt1) => {
	var diff =(dt2.getTime() - dt1.getTime()) / 1000;
	diff /= (60 * 60 * 24 * 7 * 4);
	return Math.abs(Math.round(diff));
	}
	addExpirience = () => {
		let data = {exp_title:this.state.exp_title,duration:this.diff_months(new Date(this.state.startYear,this.state.startMonth,'01'),new Date(this.state.endYear,this.state.endMonth,'01'))};
		this.setState({expiriences:[...this.state.expiriences, data],exp_title:'',startMonth:'',startYear:'',endMonth:'',endYear:''});
	}
	addLanguages = () => {
		let data = {lang_name:this.state.lang_name,lang_pro:this.state.lang_pro};
		this.setState({languages:[...this.state.languages, data],lang_name:'',lang_pro:''});
	}
	addPublication = (data) => {
		console.log("data::",data);
		data.pub_date = moment(`${data.pub_month}/${data.pub_day}/${data.pub_year}`).format('ll');
		console.log("data::",data);
		this.setState({publications:[...this.state.publications, data],isShowPublicationModal:false});
	}
	removePublication = (index) => {
		let tmpPublication = this.state.publications;
		if (index !== -1) {
			tmpPublication.splice(index, 1);
			this.setState({publications: tmpPublication});
		}
	}
	removeLanguages = (index) => {
		let tmplanguages = this.state.languages;
		if (index !== -1) {
			tmplanguages.splice(index, 1);
			this.setState({languages: tmplanguages});
		}
	}
	onChangeLanguage = (index, event) => {
		var languages = this.state.languages.slice(); 
		languages[index][event.target.name] = event.target.value;
		this.setState({ languages }); 
	}
	removeExpirience = (index) =>{
		console.log("index",index);
		let tmpExpirience = this.state.expiriences;
		if (index !== -1) {
			tmpExpirience.splice(index, 1);
			this.setState({expiriences: tmpExpirience});
		}
	}
	removeProfessionalSkills = (index) =>{
		console.log("index",index);
		let tmpprofessionalSkills = this.state.professionalSkills;
		if (index !== -1) {
			tmpprofessionalSkills.splice(index, 1);
			this.setState({professionalSkills: tmpprofessionalSkills});
		}
	}
	removeEducation = (index) =>{
		console.log("index",index);
		let tmpEducations = this.state.educations;
		if (index !== -1) {
			tmpEducations.splice(index, 1);
			this.setState({educations: tmpEducations});
		}
	}
	removeContacts = (index) =>{
		console.log("index",index);
		let tmpContacts = this.state.contacts;
		if (index !== -1) {
			tmpContacts.splice(index, 1);
			this.setState({contacts: tmpContacts});
		}
	}

	saveContactInfo = async () => {
		if(this.state.email_scope === '1'){
			this.state.email_scope = 'public';
		} else {
			this.state.email_scope = 'private';
		}

		const payload = {
			email: localStorage.getItem("email"),
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			business_companyName: this.state.business_name,
			email_visibility: this.state.email_scope,
			profession: this.state.profession,
			address: this.state.business_address,
			state_province: this.state.state,
			location: this.state.city,
			country: this.state.country,
			phoneNumbertype: this.state.phoneType,
			phoneNumber: this.state.phoneNoCode + this.state.phoneNo,
			zipCode: this.state.zipcode,
			password: localStorage.getItem("password")
		}
		try {
			const response = await fetch(FORM_API_LIST.CONTACT_API, {
				method: "POST",
				body: JSON.stringify(payload)
			});
			if(!response.ok) {
				throw new Error("Some server error occurred");
			}
			const data = await response.json();
			
			if(data.success) {
				Notify.sendNotification(data.message, 'success');
				this.setState({ Index: this.state.Index + 1 });
			} else {
				Notify.sendNotification(data.message, 'error');
			}

		} catch (error) {
			Notify.sendNotification(error, 'error');
		}
	}

	saveEducationInfo = async () => {
		const payload = {
			email: localStorage.getItem("email"),
			password: localStorage.getItem("password"),
			education: this.state.educations
		}

		try {
			const response = await fetch(FORM_API_LIST.EDUCATION_API, {
				method: "POST",
				body: JSON.stringify(payload)
			});
			if(!response.ok) {
				throw new Error("Some server error occurred");
			}
			const data = await response.json();
			if(data.success) {
				Notify.sendNotification(data.message, 'success');
				this.setState({ Index: this.state.Index + 1 });
			} else {
				Notify.sendNotification(data.message, 'error');
			}
		} catch (error) {
			Notify.sendNotification(error, 'error');
		}
	}

	saveSkillsInfo = async () => {
		let speciality = '';
		this.state.professionalSkills.forEach(item => {
			const a = Object.values(item);
			speciality += a + ',';
		});

		const payload = {
			email: localStorage.getItem("email"),
			password: localStorage.getItem("password"),
			patents: this.state.patent,
			experience: this.state.expiriences,
			speciality,
			publication: this.state.publications
		}

		try {
			const response = await fetch(FORM_API_LIST.SKILLS_API, {
				method: "POST",
				body: JSON.stringify(payload)
			});

			if(!response.ok) {
				throw new Error("Some server error occurred ")
			}

			const data = await response.json();

			if(data.success) {
				Notify.sendNotification(data.message, 'success');
				this.setState({ Index: this.state.Index + 1 });
			} else {
				Notify.sendNotification(data.message, 'error');
			}
		} catch (error) {
			Notify.sendNotification(error, 'error');
		}
	}

	saveAboutInfo = async () => {
		const payload = {
			email: localStorage.getItem("email"),
			password: localStorage.getItem("password"),
			languages: this.state.languages,
			yourWebsite: this.state.website,
			moreAboutYou: this.state.description,
			statements: this.state.statement,
			activityAndSocieties: this.state.activities,
			interestsAndHobbies: this.state.interest,
			memberships: this.state.memberships,
			aboutMe: this.state.tag_line
		}

		try {
			const response = await fetch(FORM_API_LIST.ABOUT_ME_API, {
				method: "POST",
				body: JSON.stringify(payload)
			});

			if(!response.ok) {
				throw new Error("Some server error occurred");
			}

			const data = await response.json();
			if(data.success) {
				sweetAlert(data.message, 'success', false);
				this.props.history.push('/profile');
			} else {
				Notify.sendNotification(data.message, 'error');
			}

		} catch (error) {
			Notify.sendNotification(error, 'error');
		}
	}

    save = () => {
        console.log("result:", this.state);
    }

    render() {
        return (
			<div id="progressbar" 
				 className="ui-progressbar ui-widget ui-widget-content ui-corner-all"
				crole="progressbar" 
				aria-valuemin={ 0 } aria-valuemax={ 100 } aria-valuenow={ 25 }>
	            <h6> 25 % Profile Complete </h6>
			<div className="ui-progressbar-value ui-widget-header ui-corner-left" style={ { width: "25%" } } />
			<Tabs selectedIndex={ this.state.Index } onSelect={ Index=> this.setState({ Index }) } >
				<TabList>
					<div className="basic-info step-block-custom">
						<div className="edit-info-step">
							<Tab>
								<div className="edit-block1 ">
									<p><a id="per_top" className={ `clickTopbar ${this.state.Index===0 ? 'active' : ''}` } href="javascript:;">
Contact Information </a></p>
								</div>
							</Tab>
							<Tab>
								<div className="edit-block1">
									<p><a id="edu_top" className={ `clickTopbar ${this.state.Index===1 ? 'active' : ''}` } href="javascript:;"> Education </a></p>
                                </div>
                                </Tab>
								<Tab>
									<div className="edit-block1">
										<p><a id="skil_top" className={ `clickTopbar ${this.state.Index===2 ? 'active' : ''}` } href="javascript:;"> Skill &amp; Experience </a></p>
                                    </div>
                                    </Tab>
									<Tab>
										<div className="edit-block1">
											<p><a id="moreaboutTop" className={ `clickTopbar ${this.state.Index===3 ? 'active' : ''}` } href="javascript:;"> More About You </a></p>
                                        </div>
                                        </Tab>
						</div>
					</div>
				</TabList>
				<TabPanel>
					<section id="personal_section">
						<div className="basic-info">
							<div className="field-strem">
								<div className="half">
									<div className="stream-heading"> First Name <span style={ { color: "red" } }> * </span></div>
									<div className="profile-input-block ">
										<input type="text" name="first_name" id="first_name" title="Enter your first name" value={ this.state.first_name || localStorage.getItem('firstname') } placeholder="Enter your first name" onChange={(e) =>  this.onChange(e, 'text') }/>
									</div>
								</div>
								<div className="half">
									<div className="stream-heading"> Last Name <span style={ { color: "red" } }> * </span></div>
									<div className="profile-input-block">
										<input type="text" id="last_name" name="last_name" title="Enter your last name" value={ this.state.last_name || localStorage.getItem('lastname') } placeholder="Enter your last name" onChange={(e) => this.onChange(e, 'text') } />
									</div>
								</div>
							</div>
							<div className="field-strem">
								<div className="stream-heading"> Business / Company Name </div>
								<div className="profile-input-block">
									<input type="text" name="business_name" id="business_name" title="Enter your business/company name" placeholder="Enter your business/company name" onChange={(e) => this.onChange(e, 'text') } value={ this.state.business_name } />
								</div>
							</div>
							<div className="field-strem">
								<div className="half busi-field">
									<div className="stream-heading"> Email
										<div className="radio-blocks"> Visibility:
											<div title="Show email address
publicly." className="radio-sel">
												<input defaultValue={ 1 } name="email_scope" id="pb_scope" type="radio"  value={this.state.email_scope} onChange={ this.onChange } />
												<label htmlFor="pb_scope"> Public </label>
											</div>
											<div title="Do not show email address to public." className="radio-sel">
												<input defaultValue={ 0 } name="email_scope" defaultChecked="checked" id="pr_scope" type="radio" value={this.state.email_scope} onChange={ this.onChange } />
												<label htmlFor="pr_scope"> Private </label>
											</div>
										</div>
									</div>
									<div className="profile-input-block ">
										<input readOnly="readonly" type="text" className="disabled-input" name="user_email" id="user_email" title="Enter your business email address" placeholder="Enter your business email address" value={this.state.user_email} onChange={ this.onChange }/>
									</div>
								</div>
								<div className="half cat-field">
									<div className="stream-heading"> Profession <span style={ { color: "red" } }> * </span></div>
									<div className="profile-input-block">
										<input type="text" name="profession" id="profession" className="user-categories profession_name ui-autocomplete-input" title="Select your profession" placeholder="Select your profession" autoComplete="off" value={this.state.profession} onChange={ this.onChange } />
									</div>
								</div>
							</div>
							<div className="field-strem busi-addr-field">
								<div className="stream-heading"> Business Address </div>
								<div className="profile-input-block bussineAdres">
									<textarea className="otherField" title="Enter your business address" placeholder="Enter your business address" name="business_address" id="business_address" value={this.state.business_address} onChange={ this.onChange } />
								</div>
							</div>
							<div className="field-strem busi-addr-field">
								<div className="half">
									<div className="stream-heading"> Country </div>
									<div className="profile-input-block ">
										<select className="otherField country-auto select2-hidden-accessible" name="country" id="country" tabIndex={ -1 } aria-hidden="true" value={ this.state.country } onChange={ this.onChange }>
											<option> -- - Select Country-- - </option> { countries.countries.map((itr, i) =>
											<option key={ i + 1 } value={ itr.country }> { itr.country }
												</option>)} </select><span className="select2 select2-container select2-container--default" dir="ltr" style={ { width: 383 } }><span className = "selection" ></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
									</div>
								</div>
								<div className="half selectstatecus">
									<div className="stream-heading"> State / Province <span style={ { color: "red" } }> * </span></div>
									<div className="profile-input-block">
										<select className="otherField state-auto select2-hidden-accessible" name="state" id="state" tabIndex={ -1 } aria-hidden="true" value={ this.state.state } onChange={ this.onChange }>
											<option> -- - Select State-- - </option> { states ? states.map((state) =>
											<option key={ state } value={ state }> { state } </option>):''} </select><span className="select2 select2-container select2-container--default" dir="ltr" style={ { width: 383 } }><span className =
"selection" > { /* Select your state */ } </span><span className="dropdown-wrapper" aria-hidden="true" /></span>
									</div>
								</div>
							</div>
							<div className="field-strem busi-addr-field">
								<div className="half">
									<div className="stream-heading"> City <span style={ { color: "red" } }> * </span></div>
									<div className="profile-input-block ">
										<input type="text" name="city" id="city" title="Enter your city" placeholder="Enter your city" value={ this.state.city } onChange={(e) => this.onChange(e, 'text') }/>
									</div>
								</div>
								<div className="half">
									<div className="stream-heading"> Zip Code </div>
									<div className="profile-input-block">
										<input type="text" className="otherField" id="zipcode" name="zipcode" title="Enter your zip code" placeholder="Enter your zip code" value={ this.state.zipcode } onChange={ this.onChange }/>
									</div>
								</div>
							</div>
							<div className="phone_class">
								<div className="cust-yellow field-strem">
									<div className="half">
										<div className="stream-heading"> Phone </div>
										<div className="profile-input-block phoneCodeouter">
											<select className="phoneNoCCodes select2-hidden-accessible" name="phoneNoCode" id="phoneNoCode" tabIndex={ -1 } aria-hidden="true" style={{ width: 80 }} value={ this.state.phoneNoCode } onChange={ this.onChange }>
											<option> --CountryCode-- </option>
												{countriesCodes.map((itr, i) => <option key={i+1} value={itr.dial_code} >{itr.name + ' ' + '(' +itr.dial_code + ')'}</option>)}
											</select>
											<span className="select2 select2-container select2-container--default" dir="ltr" style={ { width: 50 } }><span className = "selection" ></span><span className = "dropdown-wrapper" aria-hidden = "true" /></span>
											<input type="number" className="block-input" id="phoneNo" name="phoneNo" title="Enter your phone number" placeholder="Enter your phone number" value={ this.state.phoneNo } onChange={ this.onChange }/>
											<span className="phoneNoError" style={ { float: "left", display: "none", color: "red", fontSize: 12, marginLeft: 75 } } />
										</div>
									</div>
									<div className="half">
										<div className="stream-heading"> Type </div>
										<div className="profile-input-block plus-minus-block">
											<select title="Type" id="phoneType" name="phoneType" className="block-input" value={ this.state.phoneType } onChange={ this.onChange }>
												<option value> Type </option>
												<option value={ 'Business Phone 1' }> Business Phone 1 </option>
												<option value={ 'Business Phone 2' }> Business Phone 2 </option>
												<option value={ 'Business Phone 3 ' }> Business Phone 3 </option>
												<option value={ 'Home Phone' }> Home Phone </option>
												<option value={ 'Mobile Phone' }> Mobile Phone </option>
												<option value={ 'Business Fax Number' }> Business Fax Number </option>
											</select>
											<a title="Add Phone Number" className="actin-p-m" id="add_phoneNo" href="javascript:;" onClick={this.handleOpenModal}>
												<img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/plus-img.png" className="initial loading" data-was-processed="true" />
											</a> 
											<span className="phoneTypeError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } />
											<ReactModal isOpen={ this.state.showModal } contentLabel="Minimal Modal Example">
												<NewComponent handleClose={()=>this.setState({showModal:false})} />
											</ReactModal>							
										</div>		
									</div>
								</div>
									{this.state.contacts && this.state.contacts.map( (itr,i) => {return (<div className="profile-input-block plus-minus-block">
								<div className="tag-input-block">
									<div className="tags eduTags">
										<h3><span>{itr.phoneNoCode} {itr.phoneNo}</span> - {itr.phoneType}</h3>
										<div className="padd-className">
										<img alt="NetProReferral" style={{'marginTop':'3px'}} src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noverifiedNo.png" className="loading" data-was-processed="true"/>
										<span style={{color:'red'}}>{itr.status}</span>
										{itr.isVerify ? <span data-ccode="+91" data-pval="9565656577" data-ptype="1" data-plabel="Business Phone 1" className="verifyPhNo">Verify</span> : null}
										</div>										
										<a title="Remove Number" className="actin-p-m" href="javascript:;" onClick={()=>this.removeContacts(i)}>
											<img alt="NetProReferral" data-id="1" className="new-sub remove_phone loading" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/minus-img.png" data-was-processed="true" />
										</a>
									</div>
								</div>
							</div>)})}

								
								<div className="forPhones n-Edu">
									<input type="hidden" id="phoneCheck" defaultValue={ 1 } />{" "} </div>
								<div className="btn-block">
									<button className="btn-main-link pre effect-ad-hover effect-main" onClick={ ()=> { this.save() } } > Save &amp; Exit </button>
									<button className="btn-main-link next effect-main effect-color-hover active" id="edu_section" onClick={ ()=> { this.saveContactInfo()}} > Next </button>
								</div>
							</div>
						</div>
						</section>
							</TabPanel>
								<TabPanel>
									<div className="basic-info">
										<div id="error_mesg-edu" className="error-message1 msg-msg msgshow" style={ { display: "none" } }>
											<div className="success-common-icon" />
											<p> Education already entered.
												</p>
													<a href="javascript:void(0)" className="sucess-colse error_close">
														<i className="fa fa-close" /></a>
										</div>
										<div className="field-strem">
											<div className="halfedu">
												<div className="stream-heading"> Level </div>
												<div className="profile-input-block ">
													<select id="edu_name" name="level" className="block-input emptyField"  onChange={ this.onChange }>
														<option value> Level </option>
														<option data-name="High School" value={ 'High School' }> High School </option>
														<option data-name="Under Graduate" value={ 'Under Graduate' }> Under Graduate </option>
														<option data-name="Graduate" value={ 'Graduate' }> Graduate </option>
														<option data-name="Professional" value={ 'Professional' }> Professional </option>
														<option data-name="Certification" value={ 'Certification' }> Certification </option>
													</select> <span className="eduLevelError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } /></div>
											</div>
											<div className="halfedu">
												<div className="stream-heading"> Institution </div>
												<div className="profile-input-block ">
													<input type="text" name="institution" id="school_colg" placeholder="Enter Institution" className="block-input emptyField" value = {this.state.institution} onChange={(e) => this.onChange(e, 'text') } /> <span className="eduInsError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } />
													</div>
												</div>
												<div className="halfedu">
													<div className="stream-heading"> Degree / Certificate </div>
													<div className="profile-input-block ">
														<input type="text" name="degree" id="degree" placeholder="Enter Degree/Certificate" className="block-input emptyField" value = {this.state.degree} onChange={(e) => this.onChange(e, 'text') }/> <span className="eduDgrError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } /></div>
												</div>
												<div className="field-strem">
													<div className="halfedu"></div>
													<div className="halfedu">
														<div className="stream-heading"> Year </div>
														<div className="profile-input-block plus-minus-block">
															<select className="block-input emptyField select-scr select2-hidden-accessible" name="education_year" id="edu_year" name="edu_year" tabIndex={ -1 } aria-hidden="true" onChange={ this.onChange }>
																<option value> Year </option>
																{generateArrayOfYears().map((itr)=><option value={itr}> {itr} </option>)}
																{" "} </select><span className="select2 select2-container select2-container--default" dir="ltr" style={ { width: 100 } }><span className = "dropdown-wrapper" aria-hidden = "true" /></span>
															<a title="Add Education" className="actin-p-m" id="add_edu" onClick={this.addEductaion}><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/plus-img.png" /></a> <span className="eduEducError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } /></div>
													</div>
												</div>
												<div className="forEduSection n-Edu">
												{this.state.educations && this.state.educations.map((itr,i) => {return (<div className="profile-input-block plus-minus-block">
														<div className="tag-input-block">
														<div className="tags eduTags">
																<h3><span> {itr.degree} - </span> {itr.level}
																	</h3>
																		<p> From {itr.institution} in {itr.year}
																			</p>
																				<a title="Remove Education" className="actin-p-m" onClick={()=>this.removeEducation(i)}><img alt="NetProReferral" data-id={ 114 } className="new-sub remove_edu" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/minus-img.png" /></a>
															</div>
														</div>
													</div>)})}
												</div>
												<div className="btn-block">
													<button className="btn-main-link pre active effect-main effect-color-hover" id="prev_1" onClick={ ()=> { this.setState({ Index: this.state.Index - 1 }) } } > Previous </button>
													<button className="btn-main-link effect-main effect-ad-hover save" onClick={ this.save() }> Save &amp; Exit </button>
													<button className="btn-main-link next effect-main effect-color-hover active" id="next_2" onClick={ ()=> { this.saveEducationInfo() } } > Next </button>
												</div>
											</div>
											</div>
								</TabPanel>
								<TabPanel>
									<div className="basic-info">
										<div className="field-strem">
											<div className="stream-heading"> Your professional skills &amp; specialities </div>
											<div className="tag-input-block select2-skills">
												<div style={ { marginBottom: 30 } } className="tags">
												<ul id="skills" data-name="skills[]" className="tagit ui-sortable">
												{this.state.professionalSkills.map((itr,i)=><li className="tagit-choice ui-sortable-handle" tagvalue="2919">
													<div className="tagit-label">{itr.professionalSkill}</div>
													<a className="tagit-close"  onClick={()=>this.removeProfessionalSkills(i)}>x</a>
												</li>)}
												<li className="tagit-new" style={{'width':'150px'}}>
													<input value= {this.state.professionalSkill} className="tagit-input ui-autocomplete-input" id="professionalSkill" name="professionalSkill" type="text" placeholder="Areas of expertise" autoComplete="off" style={{'width': '100px'}} onChange={(e) => this.onChange(e, 'text') } onKeyPress={this.addProfessionalSkills}/>
												</li>
													
												</ul>
												<select className="tagit-hiddenSelect" name="skills[]" multiple="multiple" />
												</div> <span id="skillsObsceneCheck" className="error" style={ { display: "none" } } /></div>
										</div>
										<div className="field-strem">
											<div className="stream-heading"> Patents </div>
											<div className="profile-input-block bussineAdres">
												<textarea id="patent" placeholder="Enter your patents" value={this.state.patent} id="patent" name="patent" onChange={ this.onChange } className="valid" aria-invalid="false" defaultValue={ "" } />
											</div>
										</div>
										<div className="field-strem">
											<div className="stream-heading pub"> Publications( <span className="pubTotal"> 0 </span>) <span className="expend-arrow"><img alt = "NetProReferral" src = "https://dcywhuojnzfz0.cloudfront.net/assets/images/down-arrow.png" /></span>
												<input id="pubTotalCount" type="hidden" defaultValue={ 0 } />
												</div>
													<div className="publications pub-expend">
														<div style={ { display: "none" } } className="pub-details">
															<ReactModal isOpen={ this.state.isShowPublicationModal } contentLabel="Minimal Modal Example">
																<PublicationModal handleClose={()=>this.setState({isShowPublicationModal:false})} addPublication={this.addPublication}/>
															</ReactModal>
														</div>
														{this.state.publications.map((itr,i)=>{return(<div className="pub-expend-inner pubDiv205">
															<h3 className="pub-expend-title">{itr.pub_title}</h3>
															<div className="pub-expend-date">{itr.pub_date} - {itr.pub_name}</div>
															<div className="pub-expend-text">{itr.pub_description}</div>
															<div className="contributors-outer">
															  <h4>Other Contributors</h4>
															  <span>{itr.pub_contributor}</span>
															</div>
															<div className="pubpublication"><a target="_blank" href={itr.pub_url}>See Publication</a> | <a title="Remove Publication" id="pub205" className="remove-pub" href="javascript:;">
															<img src="https://dcywhuojnzfz0.cloudfront.net/assets/images/minus-img.png" className="initial loading" data-was-processed="true" onClick={()=>{this.removePublication(i)}}/></a></div>
														  </div>)})}
														<div className="before-expend">
															<ul className="pub-ul"></ul>
														</div><span title="Add Publication" className="ref_plus expend-plus pub-add">
															<img alt = "NetProReferral" src = "https://dcywhuojnzfz0.cloudfront.net/assets/images/plus-img.png" onClick={()=>{this.setState({ isShowPublicationModal: true })}}/></span>
														</div>
											</div>
											<div className="tag-heading Experience"> Experience </div>
											<div className="field-strem">
												<div id="error_mesg" className="error-message1 msg-msg expErrShow" style={ { display: "none" } }>
													<div className="success-common-icon" />
													<p className="expMsgShow" />
													<a href="javascript:void(0)" className="sucess-colse error_close">
														<i className="fa fa-close" /></a>
												</div>
												<div className="halfexp">
													<div className="stream-heading"> Field / Stream </div>
													<div className="profile-input-block ">
														<input type="text" placeholder="Title" name="exp_title" id="exp_title" value={this.state.exp_title} className="block-input" onChange={(e) => this.onChange(e, 'text') } /> <span className="expTitleError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } /></div>
												</div>
												<div className="halfexp">
													<div className="stream-heading"> From </div>
													<div className="profile-input-block">
														<select className="block-input expPeriod" id="startMonth" name="startMonth" value={this.state.startMonth} onChange={ this.onChange }>
															<option value> Month </option>
															<option value={ 1 }> January
																</option>
																	<option value={ 2 }> February </option>
																	<option value={ 3 }> March </option>
																	<option value={ 4 }> April </option>
																	<option value={ 5 }> May </option>
																	<option value={ 6 }> June </option>
																	<option value={ 7 }> July </option>
																	<option value={ 8 }> August </option>
																	<option value={ 9 }> September </option>
																	<option value={ 10 }> October </option>
																	<option value={ 11 }> November </option>
																	<option value={ 12 }> December </option>{" "} </select>
														<select className="block-input expPeriod select-scr select2-hidden-accessible" value={this.state.startYear} id="startYear" name="startYear" tabIndex={ -1 } aria-hidden="true" onChange={ this.onChange }>
															<option value> Year </option>
															{generateArrayOfYears().map((itr)=><option value={itr}> {itr} </option>)}
															{" "} </select><span className="select2
select2-container select2-container--default" dir="ltr" style={ { width: 40 } }><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span>
														<span className="dropdown-wrapper" aria-hidden="true" /> <span className="expError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } /></div>
												</div>
												<div className="halfexp">
													<div className="stream-heading todate"> To
														<div className="expTillDate">
															<input type="checkbox" name="expTillDate" id="expTillDate" />
															<label htmlFor="expTillDate"> Till Date </label>
														</div>
													</div>
													<div className="profile-input-block plus-minus-block">
														<select className="block-input expPeriod expEnd" value={this.state.endMonth} id="endMonth" name="endMonth" onChange={ this.onChange }>
															<option value> Month </option>
															<option value={ 1 }> January </option>
															<option value={ 2 }> February </option>
															<option value={ 3 }> March </option>
															<option value={ 4 }> April </option>
															<option value={ 5 }> May </option>
															<option value={ 6 }> June </option>
															<option value={ 7 }> July </option>
																<option value={ 8 }> August </option>
																<option value={ 9 }> September </option>
																<option value={ 10 }> October </option>
																<option value={ 11 }> November </option>
																<option value={ 12 }> December </option>{" "} </select>
														<select className="block-input expPeriod expEnd select-scr select2-hidden-accessible" value={this.state.endYear} id="endYear" name="endYear" tabIndex={ -1 } aria-hidden="true" onChange={ this.onChange }>
															<option value> Year </option>
															{generateArrayOfYears().map((itr)=><option value={itr}> {itr} </option>)}
															{" "} </select><span className="select2 select2-container select2-container--default" dir="ltr" style={ { width: 40 } }><span className = "selection" ><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span>
														</span><span className="dropdown-wrapper" aria-hidden="true" /></span>
														<div id="expPresent" style={ { display: "none" } }> Present </div>
														<a title="Add Experience" href="javascript:;" id="add_exp" className="actin-p-m" onClick={this.addExpirience}><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/plus-img.png" /></a> <span className="expToError" style={ { float: "left", display: "none", color: "red", fontSize: 12 } } /></div>
												</div>
											</div>
											<div className="skillSectioBlock n-Edu">
												{this.state.expiriences.map((itr,i)=>{return (<div className="profile-input-block plus-minus-block">
												<div className="tag-input-block">
												<div className="tags eduTags">
													<h3><span>{itr.exp_title}</span></h3>
													<p>{itr.duration} months</p>
												</div>
												</div>
												<a title="Remove Experience" className="actin-p-m">
													<img  data-id="68" className="new-sub remove_exp initial loading" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/minus-img.png" data-was-processed="true" onClick={()=>this.removeExpirience(i)}/>
												</a>
												</div>)})}
												</div>
													<div className="btn-block">
														<button className="btn-main-link pre effect-main active effect-color-hover" id="prev_2" onClick={ ()=> { this.setState({ Index: this.state.Index - 1 }) } } > Previous </button>
														<button className="btn-main-link effect-main effect-ad-hover save" onClick={ this.save() }> Save &amp; Exit </button>
														<button className="btn-main-link active next effect-main effect-color-hover" id="next_3" onClick={ ()=> { this.saveSkillsInfo(); } } > Next </button>
													</div>
											</div>
								</TabPanel>
								<TabPanel>
									<div className="basic-info">
										<div className="field-strem">
											<div className="stream-heading"> Tag line </div>
											<div className="profile-input-block">
												<input type="text" id="tag_line" name="tag_line" value={this.state.tag_line} onChange={(e) => this.onChange(e, 'text') } placeholder="Enter tag line" />
											</div>
										</div>
										<div className="field-strem">
											<div className="stream-heading"> Memberships </div>
											<div className="profile-input-block bussineAdres">
												<textarea className="otherField" id="memberships" name="memberships" value={this.state.memberships} onChange={(e) => this.onChange(e, 'text') } placeholder="Enter your memberships" />
											</div>
										</div>
										<div className="field-strem">
											<div className="stream-heading"> Interests and Hobbies </div>
										<div className="profile-input-block bussineAdres">
											<textarea id="interest" placeholder="Enter your interests and Hobbies" name="interest" className="valid
moreInfoField" aria-invalid="false" defaultValue={ "" }  value={this.state.interest} onChange={(e) => this.onChange(e, 'text') }/>
											<p> Examples: Management training, new technologies, investing, fishing, snowboarding, digital photography
												</p>
										</div>
									</div>
									<div className="field-strem">
										<div className="stream-heading"> Activities and Societies </div>
										<div className="profile-input-block bussineAdres">
											<textarea className="block-input moreInfoField" name="activities" placeholder="Enter your activities and societies" id="activities" value={this.state.activities} onChange={(e) => this.onChange(e, 'text') } />
										</div>
									</div>
									<div className="field-strem">
										<div className="stream-heading"> Statement </div>
										<div className="profile-input-block bussineAdres">
											<textarea className="block-input moreInfoField" name="statement" placeholder="Enter your statement" id="statement" value={this.state.statement} onChange={(e) => this.onChange(e, 'text') } />
										</div>
									</div>
									<div className="field-strem desc-field">
										<div className="stream-heading"> Tell searchers more about you
											</div>
												<div className="profile-input-block bussineAdres">
													<textarea className="block-input moreInfoField" placeholder="Enter your basic information" name="description" id="description" value={this.state.description} onChange={(e) => this.onChange(e, 'text') } />
												</div>
										</div>
										<div className="field-strem">
											<div className="stream-heading"> Your Website </div>
											<div className="profile-input-block ">
												<input type="text" name="website" id="website" placeholder="Enter your website" value={this.state.website} onChange={(e) => this.onChange(e, 'text') } />
												</div>
											</div>
											<div className="lang_class">
												<div className="mob-grid-edit field-strem addLangBloock1">
													<div className="half">
														<div className="stream-heading"> Language(s) </div>
														<div className="profile-input-block">
															<input type="text" name="lang_name" autoComplete="off" className="lang_select block-input moreInfoField"  value={this.state.lang_name} onChange={(e) => this.onChange(e, 'text') }/>
														</div>
													</div>
													<div className="half">
														<div className="stream-heading"> Proficiency </div>
														<div className="profile-input-block plus-minus-block">
															<select className="block-input" name="lang_pro" value={this.state.lang_pro} onChange={ this.onChange }>
																<option value={ 0 }> Beginner </option>
																<option value={ 1 }> Intermediate </option>
																<option value={ 2 }> Expert </option>
															</select>
														</div>
													</div>
												</div>
												{this.state.languages.map((itr,i)=>{return(
													<div className="mob-grid-edit field-strem addLangBloock2 row">
													<div className="half">
														<div className="profile-input-block activeStandard">
															<input type="text" className="lang_select block-input valid" id="lang_name" name="lang_name" aria-invalid="false" value={itr.lang_name} onChange={this.onChangeLanguage.bind(this, i)}  />
														</div>
													<div className="half">
														<div className="profile-input-block plus-minus-block">
															<select  id="lang_pro"  name="lang_pro" className="block-input valid addPhoneImgSelect" aria-invalid="false" value={itr.lang_pro} onChange={this.onChangeLanguage.bind(this, i)}>
																<option value={ 0 }> Beginner </option>
																<option value={ 1 }> Intermediate </option>
																<option value={ 2 }> Expert </option>
															</select>
															<a className="actin-p-m" href="javascript:;" onClick={()=>{this.removeLanguages(i)}}>
																<img alt="NetProReferral" className="addLangImg initial loaded" data-count="2" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/minus-img.png" data-was-processed="true"/>
															</a>
														</div></div></div>
													</div>)})}
											</div>
											<a className="addlang" onClick={this.addLanguages}> Add another language </a>
											<div className="btn-block">
												<button className="btn-main-link pre active effect-main effect-color-hover" id="prev_3" onClick={ ()=> { this.setState({ Index: this.state.Index - 1 }) } } ><span /> Previous </button>
												<button className="btn-main-link next effect-main effect-ad-hover save" onClick={()=>{this.save(); this.saveAboutInfo()}}><span /> Save </button>
											</div>
									</div>
									</TabPanel>
									</Tabs>
								</div>
								
        )
    }
}
editprofile.propTypes = {};

editprofile.defaultProps = {};

export default withRouter(editprofile);