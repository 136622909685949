import React, { Component, Fragment } from "react";
import { Card, CardHeader, CardBody } from "react-simple-card";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBIcon,
  MDBContainer, 
  MDBRow, MDBCol, 
  MDBTabPane, MDBTabContent, 
  MDBNav, MDBNavItem, 
  MDBNavLink
} from "mdbreact";
import "./ProfessionalDetails.css";

class ProfessionalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: true,
      professionalDetail: props.location.state.data,
      showContact: true,
      showSkills: false,
      items: {
        default: "1",
      }
    };
  }

  contactEventHandler = () => {
    this.setState({
      showContact: true,
      showSkills: false,
    });
  }

  skillsEventHandler = () => {
    this.setState({
      showContact: false,
      showSkills: true,
    });
  }



  socialMediaEventHandler = (e, value) => {
    console.log(value);
  };

  render() {
    return (
        <div className="container">
          <MDBCard>
            <MDBCardBody>
              <div className="custom-align">
                <img
                  height="180px"
                  src={this.state.professionalDetail._source.images[0]}
                  alt="NetProReferral"
                />
                <ol style={{ paddingLeft: "2%" }}>
                  <MDBCardTitle style={{ color: "rgb(0,139,187)" }}>
                    {this.state.professionalDetail._source.name}
                  </MDBCardTitle>
                  <br />
                  <MDBCardText>
                    <MDBIcon icon="briefcase" />{" "}
                    {this.state.professionalDetail._source.speciality}
                  </MDBCardText>
                  <br />
                  <MDBCardText>
                    <MDBIcon icon="map-marker" />{" "}
                    {this.state.professionalDetail._source.address}
                  </MDBCardText>
                  <br />
                  <MDBCardText>
                    <MDBIcon icon="phone" />{" "}
                    {this.state.professionalDetail._source.phoneNumber}
                  </MDBCardText>
                  <br />
                  <img
                    className="custom-image"
                    src="../assets/images/fb.png"
                    onClick={(e) => this.socialMediaEventHandler(e, "facebook")}
                  ></img>
                  <img
                    className="custom-image"
                    src="../assets/images/tw.png"
                    onClick={(e) => this.socialMediaEventHandler(e, "twitter")}
                  ></img>
                  <img
                    className="custom-image"
                    src="../assets/images/li.png"
                    onClick={(e) => this.socialMediaEventHandler(e, "linkedin")}
                  ></img>
                  <img
                    className="custom-image"
                    src="../assets/images/gp.png"
                    onClick={(e) =>
                      this.socialMediaEventHandler(e, "googleplus")
                    }
                  ></img>
                </ol>
                <div
                  className="parallelogram"
                  style={{ marginLeft: "30%", color: "white" }}
                >
                  <br />
                  <br />
                  <MDBCardTitle style={{ textAlign: "center" }}>
                    REFERRALS
                  </MDBCardTitle>
                  <MDBCardTitle
                    className="custom-oval"
                    style={{ textAlign: "center" }}
                  >
                    0
                  </MDBCardTitle>
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>

          <Card>
            <CardHeader>
              <a
                href="javascript:void(0)"
                onClick={this.contactEventHandler}
                className="custom-color"
              >
                Contact Information
              </a>
              &nbsp; &nbsp;
              <a
                href="javascript:void(0)"
                onClick={this.skillsEventHandler}
                className="custom-color"
              >
                Skills and Experience
              </a>
              <h5 className="custom-space custom-color">
                <a href="javascript:void(0)">References</a>
              </h5>
            </CardHeader>
            <CardBody>
              {this.state.showContact ? (
                <div>
                  <div className="custom-align">
                    <p className="custom-color">Country</p>
                    <p className="custom-space custom-color">State</p>
                  </div>
                  <div className="custom-align">
                    <p>United States</p>
                    <p className="custom-space">
                      {
                        this.state.professionalDetail._source.address
                          .split(",")[1]
                          .split(" ")[1]
                      }
                    </p>
                  </div>
                  <hr />
                  <div className="custom-align">
                    <p className="custom-color">City</p>
                    <p className="custom-space custom-color">Zip</p>
                  </div>
                  <div className="custom-align">
                    <p>{this.state.professionalDetail._source.location}</p>
                    <p className="custom-space">
                      {
                        this.state.professionalDetail._source.address
                          .split(",")[1]
                          .split(" ")[2]
                      }
                    </p>
                    <p className="custom-space">No References Found</p>
                  </div>
                  <hr />
                  <div className="custom-align">
                    <p className="custom-color">Business Phone 1</p>
                  </div>
                  <div className="custom-align">
                    <p>{this.state.professionalDetail._source.phoneNumber}</p>
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.professionalDetail._source.strengths.map(
                    (item, index) => (
                      <div key={item}>
                        <p className="oval">{item}</p>
                        {index === 0 && (
                          <p style={{ marginLeft: "70%" }}>
                            No References Found
                          </p>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
              <hr />
            </CardBody>
          </Card>
        </div>
    )
  }
}

export default ProfessionalDetails;
