// auth-service.js
import { API_BASE_URL } from "./index";
import { request } from "../utils/ApiUtils";

export function getActiveUser() {
    return request({
        url: API_BASE_URL + "/api/user/user",
        method: 'GET'
    });
}
