import React, { Component, Fragment } from "react";
import { Modal } from "react-responsive-modal";
import Notify from "../../utils/AlertNotification";

class ProfileModal extends Component {
  constructor(props) {
    super();
    this.state = {
      data: props.modalData,
      open: props.openModal,
      inputType: "text",
      userInput: "",
      userId: localStorage.getItem("id"),
      userEmail: localStorage.getItem("email"),
      userPassword: localStorage.getItem("password")
    };
  }

  componentDidMount() {
    this.state.data.includes("Phone") && this.setState({ inputType: "number" });
  }

  closeModal = () => {
    this.props.onCloseModal(true);
  };

  userChange = (e) => {
    this.setState({
      userInput: e.target.value,
    });
  };

  updateData = async (data) => {
    const apiUrl =
      "https://8gsdr56bhd.execute-api.us-west-2.amazonaws.com/dev/updatePersonalInfo";
    let payload = {
      address: "",
      phoneNumber: "",
      profession: "",
      aboutMe: "",
      id: this.state.userId,
      email: this.state.userEmail,
      password: this.state.userPassword
    };

    if (data.includes("Phone")) payload.phoneNumber = this.state.userInput;
    else if (data.includes("Basic")) payload.aboutMe = this.state.userInput;
    else if (data.includes("Address")) payload.address = this.state.userInput;
    else payload.profession = this.state.userInput;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error("Some server error has occurred");
      }

      const data = await response.json();
      if(data.success) {
        Notify.sendNotification(data.message, 'success');
      } else {
        Notify.sendNotification(data.message, 'error');
      }
    } catch (error) {
        Notify.sendNotification(error, 'error');
    }
  };

  render() {
    return (
      <Fragment>
        <Modal open={this.state.open} onClose={this.closeModal} center>
          <h3>Update {this.state.data}</h3>
          <br /> <br /> <br />
          <span forhtml="user-input">{this.state.data}</span> &nbsp; &nbsp;
          <input
            id="user-input"
            className="input-block"
            type={this.state.inputType}
            onChange={(e) => {
              this.userChange(e);
            }}
          />
          <br />
          <br />
          <br />
          <br />
          <button
            style={{ margin: "auto" }}
            className="pop-up-btn effect-main effect-color-hover"
            onClick={() => {
              this.closeModal();
              this.updateData(this.state.data);
            }}
          >
            Update
          </button>
        </Modal>
      </Fragment>
    );
  }
}

export default ProfileModal;
