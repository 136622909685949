import React, { Component, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import { getConversationsByUser,getMessagesByConversation, postMessage, deleteMessagesByUser } from "./logic";
import ReactModal from "react-modal";
import Moment from 'react-moment';
import Loader from '../../utils/loader'

class Message extends Component {
   constructor() {
      super();
      this.state = {
         showModal: false,
         userFrom: "",
         userTo: "",
         currentUser: "",
         messageText: "",
         errors: {},
         messageEmpty: false,
         message: [],
         lastmessage: [],
         conversation: [],
         loading: false,
         currentConversationId: ""
      };
      this.state.message = [];
      this.state.conversation = [];
      this.state.userTo = {};
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
      this.getConversationsByUser = this.getConversationsByUser.bind(this);
      //this.getMessagesByConversation = this.getMessagesByConversation.bind(this);
      this.postMessage = this.postMessage.bind(this);
      this.deleteMessagesByUser = this.deleteMessagesByUser.bind(this);
      
   }
   componentDidMount() {
      this.state.currentUser = JSON.parse(localStorage.getItem("ActiveUser"));
      /*let message = [{ name: "ajit", subject: "this is good", message: "good developer" }, { name: "ajit1", subject: "this is not good", message: "good developer" }, { name: "ram", subject: "this is very good", message: "good developer" }]*/   
      this.getConversationsByUser();

   }

   onChange = (e) => {
      const { name, value } = e.target;
      console.log(name, value);
      this.setState({
        [name]: value,
      })
    };

   handleOpenModal(conversationId) {
      this.setState({ currentConversationId: conversationId });
      this.getMessagesByConversation(conversationId);
      this.setState({ showModal: true });
   }

   handleCloseModal() {
      this.setState({ showModal: false });
   }

   getMessagesByConversation(conversationId){
      this.setState({ loading: true });
      getMessagesByConversation(conversationId)
         .then((response) => {
            if ((response.data.status = "SUCESS")) {
               if(response.data.data.length > 0){
                  let messageData = response.data.data[0];
                  if(this.state.currentUser.id == messageData.fromUser.id){
                     this.setState({ userTo: messageData.toUser });
                  }else{
                     this.setState({ userTo: messageData.fromUser });
                  }
                  this.setState({ message: response.data.data });
               }
            }
         })
         .catch((error) => {
            if (error.status === 401) {
            } else {
            }
         });
   }

   getConversationsByUser() {
      //let user = JSON.parse(localStorage.getItem("ActiveUser"));
      getConversationsByUser(this.state.currentUser.id)
         .then((response) => {
            if ((response.data.status = "SUCESS")) {
               if(response.data.data != null){
                  this.setState({ conversation: response.data.data });
               }
               //   this.setState({
               //     userProfilePicture: response.data.data.base64Img
               //   });
            }
         })
         .catch((error) => {
            if (error.status === 401) {
            } else {
            }
         });
   }
   postMessage() {
      let user = JSON.parse(localStorage.getItem("ActiveUser"));
      let messageData = {
         conversationId : this.state.currentConversationId,
         userFrom: this.state.currentUser.id,
         userTo: this.state.userTo.id,
         message: this.state.messageText,
         subject: this.state.conversation[0].lastMessage.subject
      };
      postMessage(messageData)
         .then((response) => {
            if ((response.data.status = "SUCESS")) {
               //alert(response.data.message);
               this.getMessagesByConversation(this.state.currentConversationId);
               this.setState({ messageText: "" });
            }
         })
         .catch((error) => {
            if (error.status === 401) {
            } else {
            }
         });
   }

   deleteMessagesByUser(messageId) {
      deleteMessagesByUser(messageId)
         .then((response) => {
            if ((response.data.status = "SUCESS")) {
               //   this.setState({
               //     userProfilePicture: response.data.data.base64Img
               //   });
            }
         })
         .catch((error) => {
            if (error.status === 401) {
            } else {
            }
         });
   }

   render() {
      return (
         <div className="basic-info">
            <div class="dashboard-inner">
               <div class="container" style={{ border: "none" }}>
                  <div class="top-heading-bar">
                     <h3>
                        <input title="Select All" type="checkbox" id="select_all" name="select_all" onclick="return selectAll($(this));" />
                        <label for="select_all">
                           <b>Select All</b>
                        </label>
                     </h3>

                     <ul class="mail-ico" style={{ display: "none" }}>
                        <li id="markRead" title="Mark as read" onclick="readUnreadThreads(1);">
                           <a><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/mail-box1.png" />
                           </a>
                        </li>
                        <li id="markUnRead" title="Mark as unread" onclick="readUnreadThreads(2);"><a><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/mail-box.png" /></a></li>
                        <li id="delConv" title="Delete Conversation" onclick="deleteThreads();"><a><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/del.png" /></a></li>
                     </ul>
                  </div>
                  <div class="tb-content-block-outer">
                     
                     {this.state.conversation == null ? (
                        <div id="tableBody" class="tb-content-block">
                           <div class="no-conv-found">No conversation found.</div>
                        </div>
                     ) : (
                        <div>
                        {this.state.conversation.map((x, i) => {
                           return (
                              <div id="tableBody" className="tb-content-block" >
                                 {this.state.conversation != null ? (
                                    <div style={{ cursor: 'pointer' }} id="div2663" className="tb-content-row row read-msg">
                                       <div className="tb-content-cell  b-block-b">
                                          <div className="input-table"><input type="checkbox" id={2663} name={2663} /><label htmlFor={2663} /></div>
                                          <div onClick={() => this.handleOpenModal(x.id)} id="img2663" className="list-user-img-bluck active"><img alt="NetProReferral" className="img-1-blk initial loading" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.png" data-was-processed="true" /></div>
                                          <p onClick={() => this.handleOpenModal(x.id)} id="name2663">{x.lastMessage.fromUser.firstName} {x.lastMessage.fromUser.lastName}</p>
                                       </div>
                                       <div onClick={() => this.handleOpenModal(x.id)} id="content2663" className="tb-content-cell  b-block-c">
                                          <p id="newContent2663"><span id="subject2663"><b>{x.lastMessage.subject}</b></span> {x.lastMessage.message}</p>
                                       </div>
                                       <div onClick={() => this.handleOpenModal(x.id)} id="date2663" className="tb-content-cell  time-cell">
                                          <p id="newDate2663">
                                             <Moment format="hh:mm a D MMM YYYY" withTitle>{x.lastMessage.created}</Moment>
                                          </p>
                                          {/* <p id="newDate2663">08:02 pm Sep 13, 2020</p> */}
                                       </div>
                                    </div>
                                 ) : <div id="tableBody" class="tb-content-block">
                                       <div class="no-conv-found">No conversation found.</div>
                                    </div>}
                              </div>
   
                           );
                        })}
                        </div>
                     )}
                  </div>
               </div>
            </div>

            <ReactModal
               isOpen={this.state.showModal}
               contentLabel="Minimal Modal Example"
               className="Modal"
               overlayClassName="Overlay"
               onRequestClose={this.handleCloseModal}
            >
               <Loader props={this.state.loading}></Loader>
               <div style={{ display: 'block' }} className="fixed-pop-outer">
                  <div id="conversation-div" className="pop-inner">
                     <div className="pop_cross">
                     <a onclick="closeConv();" className="cross"><img  onClick={this.handleCloseModal} alt="Close" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/multiply.png" /></a>
                  </div><div className="npopup_inner_content"><div className="top-heading-bar">
                     <div className="top-border-bottom">
                     <h3>
                        <span>Conversation with {this.state.userTo.firstName} {this.state.userTo.lastName} ( {this.state.message.length} )</span>
                     </h3>
                        <ul className="mail-ico">
                           <li title="Mark as unread" onclick="readUnreadThreads(2,2664);"><a><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/nclose.png" /></a></li>
                           <li title="Delete Conversation" onclick="deleteThreads(2664);"><a><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/ndelete.png" /></a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="chat-block-new-1">
                  
                     <div className="chat-block-new-scroll" tabIndex={5}>
                        <div className="chat-block-scroll">
                        {this.state.message.map((x, i) => {
                           return (
                              <div>
                                 {x.fromUser.id == this.state.currentUser.id ? (
                           <div className="chat-inner-main ">
                              <a target="_blank" title="" href="https://www.netproreferral.com/m/vijay-vijay-ee35e8ea7f7d6969180bf29f38abaf83"><div className="chat-img1"><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.png" /></div></a>
                              <div className="chat-right-div">
                                 <p>{x.message}</p>
                              </div>
                              <p className="time-block">
                                 <Moment format="hh:mm a MMM D, YYYY" withTitle>{x.created}</Moment>
                              </p>
                           </div>
                           ) : <div className="chat-inner-main ">
                                 <a target="_blank" title="" href="https://www.netproreferral.com/m/vijay-vijay-ee35e8ea7f7d6969180bf29f38abaf83"><div className="chat-img1"><img alt="NetProReferral" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.png" /></div></a>
                                 <div className="chat-right-div">
                                    <p>{x.message}</p>
                                 </div>
                                 <p className="time-block">
                                    <Moment format="hh:mm a MMM D, YYYY" withTitle>{x.created}</Moment>
                                 </p>
                              </div>
                              }
                              </div>
                            );
                           })}

                        </div>
                     </div>
                  <div className="chat-inner-main chat-inner-main-left">
                              <div className="chat-img1"><img alt="NetProReferral" id="curUserImgPath" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.png" /></div>
                              <div className="chat-right-div chat-input-div">
                                 <textarea name="messageText" id="messageText" placeholder="Type your message here..." value={this.state.messageText} onChange={this.onChange}/>
                                 <p id="content-err-msg-content" style={{ fontSize: '12px', color: 'rgb(255, 0, 0)' }}>Please enter message.</p>
                                 <input id="userID" type="hidden" defaultValue={1635535} />
                                 <input id="vuserEmail" type="hidden" defaultValue />
                              </div>
                              <div className="btn-snd-block">
                                 <button className="effect-main effect-color-hover" onClick={this.postMessage}>SEND MESSAGE</button>
                              </div>
                           </div>
                        </div></div><div id="ascrail2005" className="nicescroll-rails nicescroll-rails-vr" style={{ width: '8px', zIndex: 9999, cursor: 'default', position: 'absolute', top: '97.8px', left: '823px', height: '110px', display: 'none', opacity: 0, transitionProperty: 'opacity', transitionDuration: '400ms', transitionTimingFunction: 'ease-in-out' }}><div className="nicescroll-cursors" style={{ position: 'relative', top: '0px', float: 'right', width: '6px', height: '0px', backgroundColor: 'rgb(0, 175, 235)', border: '1px solid rgb(255, 255, 255)', backgroundClip: 'padding-box', borderRadius: '5px' }} /></div><div id="ascrail2005-hr" className="nicescroll-rails nicescroll-rails-hr" style={{ height: '8px', zIndex: 9999, top: '199.8px', left: '15px', position: 'absolute', cursor: 'default', display: 'none', opacity: 0, transitionProperty: 'opacity', transitionDuration: '400ms', transitionTimingFunction: 'ease-in-out' }}><div className="nicescroll-cursors" style={{ position: 'absolute', top: '0px', height: '6px', width: '0px', backgroundColor: 'rgb(0, 175, 235)', border: '1px solid rgb(255, 255, 255)', backgroundClip: 'padding-box', borderRadius: '5px', left: '0px' }} /></div></div>
               </div>
            </ReactModal>
         </div>
      );
   }
}

Message.propTypes = {};

Message.defaultProps = {};

export default withRouter(Message);
