import React, { Component, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import Loader from "../../utils/loader";
import queryString from "query-string";
import { getActiveUser } from "../../common/commonService";
import { NavLink } from "react-router-dom";
import {
  forgotAction,
  forgotReset,
  loginAction,
  registerAction,
} from "./logic";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./style.css";
import Notify from "../../utils/AlertNotification";
import APIS_LIST from "./apiList";
import { Data } from '../../common/professionsList.json';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
      showForgot: false,
      showSignup: false,
      showVerificationPopUp: false,
      newPassword: "",
      confirmNewPassword: "",
      claimProfilePopup: false,
      forgotEmail: "",
      loginEmail: "",
      directlogin: true,
      loginPass: "",
      login: true,
      loading: false,
      register: false,
      terms: false,
      captcha: false,
      newForgotEmail: '',
      formErrors: {
        forgotEmail: "",
        loginEmail: "",
        loginPass: "",
        verifyCode: "",
      },
      termsAndConditions: false,
      termsError: '',
      registration: {
        firstName: "",
        lastName: "",
        profession: "",
        email: "",
        password: "",
        confirmPassword: "",
        validEmail: true,
      },
      professionsList: [],
      showProfessionsList: false,
      verificationCode: "",
      validPassword: false,
      passwordMatch: false,
      memberFirstName: '',
      memberLastName: ''
    };
  }

  isUserReferred = () => {
    if(this.props.location.pathname !== '/') {
      const referralIdIndex = (this.props.location.pathname).indexOf('id-');
      if(referralIdIndex !== -1) {
        this.setState({showSignup: true});
        localStorage.setItem("referralId", (this.props.location.pathname).slice(referralIdIndex + 3));
      }
    }
  }

  componentDidMount() {

    if(localStorage.getItem('firstname') !== null) {
      this.setState({
        memberFirstName: localStorage.getItem("firstname"),
        memberLastName: localStorage.getItem("lastname")
      });
    }

    this.isUserReferred();

    if (localStorage.getItem("isAuthenticated")) {
      getActiveUser()
        .then((response) => {
          localStorage.setItem("ActiveUser", JSON.stringify(response.data));
          this.setState({ userFirstName: response.data.firstName });
          this.setState({ userLastName: response.data.lastName });
          this.getProfilePicture();
        })
        .catch((error) => {});
    }

    if (
      this.props.location.pathname.includes("login") &&
      !localStorage.getItem("isAuthenticated")
    ) {
      this.setState({ claimProfilePopup: true });
    } else {
      if (
        this.props.location.pathname.includes("login") &&
        localStorage.getItem("isAuthenticated")
      )
        this.props.history.push(
          "/refer/" + this.props.location.pathname.split("/")[2]
        );
    }
    // document.body.style.overflow = "hidden"
  }

  claimNewProfile = () => {
    this.setState(
      {
        claimProfilePopup: false,
        showLogin: true,
        showForgot: false,
        showSignup: false,
      },
      () => {
        document.body.style.overflow = "scroll";
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    var loggedUser = JSON.parse(localStorage.getItem("ActiveUser"));

    if (loggedUser != null) {
      this.setState({ userFirstName: loggedUser.firstName });
      this.setState({ userLastName: loggedUser.lastName });
    }
    //this.setState({loading:true})
    if (nextProps.registerData.data.status == "SUCCESS") {
      this.setState({ claimProfilePopup: false });

      this.setState({ loading: false });

      if (this.props.location.pathname.includes("login")) {
        var email = this.state.email;
        var pass = this.state.password;
        this.setState({ loginEmail: email });
        this.setState({ loginPass: pass });
        if (this.state.directlogin == true) {
          this.onLoginClick(email, pass);
        }
      } else {
        this.setState(
          {
            showLogin: true,
            showForgot: false,
            showSignup: false,
          },
        );
      }
    } else {
      if (nextProps.registerData.data.status == "FAIL") {
        this.setState({ loading: false });
      }
    }

    if (
      nextProps.loginData &&
      nextProps.loginData.data &&
      nextProps.loginData.data.status === "SUCCESS" &&
      this.state.login
    ) {
      this.setState(
        {
          login: false,
          loading: false,
        },
        () => {
          localStorage.setItem("login", nextProps.loginData.data);
          localStorage.setItem(
            "ACCESS_TOKEN",
            nextProps.loginData.data.data.token
          );
          if (nextProps.loginData.data.data.token) {
            localStorage.setItem("isAuthenticated", true);
            //window.isAuthenticated = true
          }

          //setCookie(nextProps.loginData.data.data.token,'accessToken');
          // alert('Cookie: ', getCookie('accessToken'))

          if (this.props.location.pathname.includes("login")) {
            let url = this.props.location.search;
            let params = queryString.parse(url);

            this.props.history.push(
              "/refer/" + this.props.location.pathname.split("/")[2]
            );
          } else {
            this.props.history.push("/dashboard");
          }
          window.location.reload();
        }
      );
    } else {
      if (nextProps.loginData.data.status == "FAIL") {
        this.setState({ loading: false });
      }
    }
  }

  getProfessionalList = () => {
    this.setState({
      professionsList: Data.occupations,
      showProfessionsList: true
    });
  }
  
  userSelect = (data) => {
    this.setState({
      registration: {
        ...this.state.registration,
        profession: data
      },
      showProfessionsList: false
    });
  }

  toggle = () => {
    this.setState({ terms: !this.state.terms }, () =>
      console.log(this.state.terms)
    );
  };

  toggle1 = () => {
    this.setState({ captcha: !this.state.captcha }, () =>
      console.log(this.state.captcha)
    );
  };

  onModalClose = () => {
    this.setState({
      showLogin: false,
      showForgot: false,
      showSignup: false,
      registration: {
        firstName: "",
        lastName: "",
        profession: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    });
  };

  showLoginPopup = () => {
    this.setState(
      {
        showLogin: true,
        showForgot: false,
        showSignup: false,
      },
    );
  };

  showForgotPopup = () => {
    this.setState(
      {
        showLogin: false,
        showForgot: true,
        showSignup: false,
      },
    );
  };

  showSignupPopup = () => {
    this.setState(
      {
        showLogin: false,
        showForgot: false,
        showSignup: true,
      },
    );
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  IsValid = (email) => {
    this.setState({
      formErros: {
        fogotEmail: "",
      },
    });
    let filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (email) {
    } else {
      this.setState({
        formErros: {
          fogotEmail: "Please enter your email address.",
        },
      });
      return false;
    }

    if (filter.test(email)) {
      return true;
    } else {
      this.setState({
        formErros: {
          fogotEmail: "Please enter a valid email address.",
        },
      });
      return false;
    }
  };

  IsLoginValid = (email, pass) => {
    this.setState({
      formErros: {
        loginEmail: "",
      },
    });
    let filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (email) {
    } else {
      this.setState({
        formErros: {
          loginEmail: "Please enter your email address.",
        },
      });
      return false;
    }

    if (filter.test(email)) {
    } else {
      this.setState({
        formErros: {
          loginEmail: "Please enter a valid email address.",
        },
      });
      return false;
    }

    if (pass) {
    } else {
      this.setState({
        formErros: {
          loginPass: "Please enter your email password.",
        },
      });
      return false;
    }
    return true;
  };

  IsRegistrationValid = (obj) => {
    let filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let fiterPass =
      "^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$";
    const regex = RegExp(
      "^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$"
    );

    if (obj.firstName) {
    } else {
      this.setState({
        formErros: {
          firstName: "Please enter first name.",
        },
      });
      return false;
    }

    if (obj.lastName) {
    } else {
      this.setState({
        formErros: {
          lastName: "Please enter last name.",
        },
      });
      return false;
    }

    if (obj.profession) {
    } else {
      this.setState({
        formErros: {
          profession: "Please enter profession.",
        },
      });
      return false;
    }

    if (obj.email) {
    } else {
      this.setState({
        formErros: {
          email: "Please enter email address.",
        },
      });
      return false;
    }

    if (filter.test(obj.email)) {
    } else {
      this.setState({
        formErros: {
          email: "Please enter a valid email address.",
        },
      });
      return false;
    }

    if (obj.password) {
    } else {
      this.setState({
        formErros: {
          password: "Please enter password.",
        },
      });
      return false;
    }

    if (obj.cpassword) {
    } else {
      this.setState({
        formErros: {
          cpassword: "Please enter confirm password.",
        },
      });
      return false;
    }

    if (obj.cpassword && obj.password && obj.cpassword === obj.password) {
    } else {
      this.setState({
        formErros: {
          cpassword: "Please enter the same password.",
        },
      });
      return false;
    }

    if (obj.terms) {
    } else {
      this.setState({
        formErros: {
          terms: "Please accept Terms Of Service.",
        },
      });
      return false;
    }
    return true;
  };

  onForgotSubmit = async () => {
    {
      if(!this.state.newForgotEmail) {
        this.setState({
          formErrors: {
            forgotEmail: 'Enter a valid email'
          }
        });
        return 0;
      } else {
        this.setState({
          formErrors: {
            forgotEmail: ''
          }
        });
      }

      if(!this.state.showVerificationPopUp) {
        try {

          this.setState({
            loading: true
          });
          
          const response = await fetch(APIS_LIST.FORGOT_PASSWORD, {
            method: "POST",
            body: JSON.stringify({email: this.state.newForgotEmail})
          });

          if(!response.ok) {
            throw new Error('An unknown error has occurred. Try again later.')
          }

          const data = await response.json();

          if(data.success) {
            Notify.sendNotification(data.message, 'success');
            this.setState({showVerificationPopUp: true});
          } else {
            Notify.sendNotification(data.message, 'error');
          }

          this.setState({
            loading: false
          });
        } catch (error) {
          Notify.sendNotification(error, 'error');
        }
    } else {
        if(!this.state.verificationCode) {
          this.setState({
            formErrors: {
              verifyCode: 'Enter a valid code'
            }
          });
          return 0;
        } else {
          this.setState({
            formErrors: {
              verifyCode: ''
            }
          });
        }

        if(!this.state.newPassword) {
          this.setState({
            formErrors: {
              forgotEmail: 'Enter a valid password'
            }
          });
          return 0;
        } else {
          this.setState({
            formErrors: {
              forgotEmail: ''
            }
          });
        }

        const validatePassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&.^])[A-Za-z\d#@$!%*?&.^]{8,}$/;
        if(!validatePassword.test(this.state.newPassword)) {
          this.setState({
            formErrors: {
              forgotEmail: 'Password Must Contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
            }
          });
          return 0;
        }

        try {
          this.setState({
            loading: true
          });

          const response = await fetch(APIS_LIST.CONFIRM_PASSWORD, {
            method: "POST",
            body: JSON.stringify({
              email: this.state.newForgotEmail,
              verificationCode: this.state.verificationCode,
              newPassword: this.state.newPassword
            })
          });

          if(!response.ok) {
            throw new Error('An unknown error has occurred. Try again later.');
          }
          
          const data = await response.json();

          if(data.success) {
            this.onModalClose();
            Notify.sendNotification(data.message, 'success');
          } else {
            Notify.sendNotification(data.message, 'error');
          }

          this.setState({
            loading: false
          });
        } catch (error) {
          Notify.sendNotification(error, 'error');
        }
      }
  }
  };

  onLoginClick = async (username, pass) => {
    var userName;
    var password;
    if (username && pass) {
      userName = username;
      password = pass;
      this.setState({ directlogin: false });
    } else {
      userName = this.state.loginEmail;
      password = this.state.loginPass;
    }

    if(!userName) {
      this.setState({
        formErrors: {
          loginEmail: 'Enter an email'
        }
      });
      return 0;
    }

    if(!password) {
      this.setState({
        formErrors: {
          loginPass: 'Enter a password'
        }
      });
      return 0;
    } else {
      this.setState({
        formErrors: {
          loginPass: ''
        }
      });
    }

    if (this.IsLoginValid(userName, password)) {
      this.setState({ loading: true });

      try {
        const response = await fetch(APIS_LIST.LOGIN_USER, {
          method: "POST",
          body: JSON.stringify({ email: userName, password }),
        });
        
        if(!response.ok) {
          throw new Error('An unknown error has occurred. Try again later.');
        }

        const data = await response.json();
          
        if (data.success) {
          localStorage.setItem("token", `Bearer ${data.Data.accessToken}`);
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("email", userName);
          localStorage.setItem("password", password);
          localStorage.setItem("id", data.Data.id);
          this.setState({loading: false});
          Notify.sendNotification(data.message, 'success');
          this.onModalClose();
          this.props.history.push('/dashboard#/profile');
        } else {
          this.setState({loading: false});
          if(!data.message.includes('User does not exist.')) {
            Notify.sendNotification(data.message, 'error');
          } else {
            this.setState({
              formErrors: {
                loginEmail: data.message
              }
            });
          }
        }
      }
        catch(error) {
          this.setState({loading: false});
          Notify.sendNotification(error, 'error');
        };
    } 
    
    else {
      this.setState({
        formErrors: {
          loginEmail: 'Enter a valid email'
        }
      });
      return 0;
    }
  };

  onRegisterSubmit = async (e) => {
    
    e.preventDefault();

    const { firstName, lastName, profession, email, password, validEmail } =
      this.state.registration;

      if (email) {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        this.setState({
          registration: { ...this.state.registration, validEmail: false },
        });
      } else {
        this.setState({
          registration: { ...this.state.registration, validEmail: true },
        });
      }
    }

    const validatePassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&.^])[A-Za-z\d#@$!%*?&.^]{8,}$/;
    if(validatePassword.test(password)) {
      this.setState({
        validPassword: true
      });
    } else {
      this.setState({
        validPassword: false
      });
    }

    if(this.state.registration.password !== this.state.registration.confirmPassword) {
      this.setState({
        passwordMatch: false
      });
    } else {
      this.setState({
        passwordMatch: true
      });
    }

    if(!this.state.termsAndConditions) {
      this.setState({
        termsError: 'Read the terms and conditions'
      });
      return 0;
    } else {
      this.setState({
        termsError: ''
      });
    }

    this.setState({ register: true });
    if (
      firstName &&
      lastName &&
      profession &&
      email &&
      password &&
      validEmail &&
      this.state.validPassword &&
      this.state.passwordMatch &&
      this.state.termsAndConditions
    ) {
      try {
        this.setState({ loading: true });
        const response = await fetch(APIS_LIST.REGISTER_USER, {
          method: "POST",
          body: JSON.stringify({
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            profession,
            refID: localStorage.getItem("referralId") || ''
          }),
        })
        if(!response.ok) {
          throw new Error('An unknown server error has occurred. Try again later.');
        } 
        
        const data = await response.json();
        
        if(data.success === true) {
          this.setState({ loading: false });
          Notify.sendNotification(data.message, 'success');
          localStorage.setItem("isAuthenticated", true, "email", email,"password", password);
          this.onModalClose();
          this.props.history.push('/dashboard#');
        } else {
          this.setState({ loading: false });
          Notify.sendNotification(data.message, 'error');
        }
      } catch (error) {
        Notify.sendNotification(error, 'error');
      }
      
    }
  };

  onForgotClose = () => {
    this.props.forgotReset();
  };

  showProfile = () => {
    this.props.history.push('/dashboard#/profile');
  }

  Dologout = () => {
    this.setState(
      {
        login: true,
      },
      () => {
        localStorage.clear();
        this.props.history.push("/");
      }
    );
  };

  verificationCodeHandler = (e) => {
    this.setState({verificationCode: e.target.value});
  }

  newPasswordHandler = (e) => {
    this.setState({newPassword: e.target.value});
  }

  forgotEmailHandler = (e) => {
    this.setState({newForgotEmail: e.target.value});
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState(this.state.registeration({ name: value }));
  }

  render() {
    let data = localStorage.getItem("login");
    const { register, login } = this.state;
    return (
      <Fragment>
        <Loader props={this.state.loading}></Loader>
        <header>
          <div className="container-fluid containter-top">
            <div className="relative">
              <nav
                className="navbar navbar-expand-lg navbar-dark bg-dark"
                aria-label="Ninth navbar example"
              >
                <div className="container-xl">
                  <div className="logo-block">
                    <NavLink exact activeClassName="active" to="/">
                      <img
                        alt=""
                        src="https://dcywhuojnzfz0.cloudfront.net/assets/images/logo.svg"
                        className="initial loading"
                        data-was-processed="true"
                      />
                    </NavLink>
                  </div>
                  <a className="l1-login claim-pop">
                    Claim <span>Your</span> Profile
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample07XL"
                    aria-controls="navbarsExample07XL"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarsExample07XL">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        {/* <a className="nav-link active" href="/">Home</a> */}
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="myactive"
                          to="/"
                        >
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="myactive"
                          to="/howitworks"
                        >
                          How it Works
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="myactive"
                          to="/blog"
                        >
                          Blog
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          exact
                          className="nav-link"
                          activeClassName="myactive"
                          to="/writeforus"
                        >
                          Write For Us
                        </NavLink>
                      </li>
                    </ul>

                    <form>
                      <section className="follow-us-block right-menu-claim claim-bar-desk">
                        <div className="desk-join-slink-block">
                          <div className="wrapper">
                            <div className="reserved-block">
                              <div className="join-us-block">
                                {localStorage.getItem("isAuthenticated") ? <Fragment>
                                  <strong>Welcome! {this.state.memberFirstName+ ' ' + this.state.memberLastName}</strong> &nbsp; 
                                  
                                  {localStorage.getItem("token") && <a
                                  className="claim-pop effect-main effect-color-hover"
                                  onClick={this.showProfile}
                                  href="javascript:void(0);"
                                >
                                  My Profile
                                </a>}
                                  <a
                                  className="claim-pop effect-main effect-color-hover"
                                  onClick={this.Dologout}
                                  href="javascript:void(0);"
                                >
                                  Log Out
                                </a> 
                                </Fragment>: 
                                <Fragment>
                                <span>
                                  Get Referred by your mentors, peers and other
                                  experts
                                </span>
                                <a
                                  className="claim-pop effect-main effect-color-hover"
                                  onClick={this.showSignupPopup}
                                  href="javascript:void(0);"
                                >
                                  Claim Your Profile
                                </a>
                                <a
                                  className="userlink login-pop"
                                  onClick={this.showLoginPopup}
                                  href="javascript:void(0);"
                                >
                                  Login
                                </a>
                                  </Fragment>}
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mob-join-slink-block">
                          <div className="mob-join-block">
                            <div className="wrapper">
                              <div className="reserved-block">
                                <div className="join-us-block">
                                  <span>
                                    Get Referred by your mentors, peers and
                                    other experts
                                  </span>
                                  <a
                                    className="claim-pop effect-main effect-color-hover"
                                    href="javascript:void(0);"
                                  >
                                    {" "}
                                    Claim Your Profile
                                  </a>
                                  <a
                                    className="userlink login-pop"
                                    href="javascript:void(0);"
                                  >
                                    {" "}
                                    Login
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="slde-arr-mob">
                            <a
                              className="downImg"
                              onClick={(e) => {return this.hideDiv()}}
                              href="javascript:void(0);"
                            ></a>
                          </div>
                        </div>
                      </section>
                    </form>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>

        <a
          style={{ padding: "6px 7px 14px", fontSize: "14px" }}
          className="btn-orange-main"
          href="/seoauditor"
        >
          <span className="checkyournt">
            <span className="checkaccounttxt">
              Check Your <b>SEO</b> Score <b>Free</b>&nbsp;&nbsp;
              <i className="fa fa-angle-double-right"></i>
            </span>
          </span>
        </a>
        <section className="follow-us-block">
          <div className="desk-join-slink-block">
            <div className="wrapper">
              <div className="reserved-block">
                <div className="join-us-block">
                  <span>
                    Get Referred by your mentors, peers and other experts
                  </span>
                  <a
                    className="claim-pop effect-main effect-color-hover"
                    href="javascript:void(0);"
                  >
                    {" "}
                    Claim Your Profile
                  </a>
                  <a className="userlink login-pop" href="javascript:void(0);">
                    {" "}
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-join-slink-block">
            <div className="mob-join-block">
              <div className="wrapper">
                <div className="reserved-block">
                  <div className="join-us-block">
                    <span>
                      Get Referred by your mentors, peers and other experts
                    </span>
                    <a
                      className="claim-pop effect-main effect-color-hover"
                      href="javascript:void(0);"
                    >
                      {" "}
                      Claim Your Profile
                    </a>
                    <a className="userlink login-pop" href="javascript:void(0);">
                      {" "}
                      Login
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="slde-arr-mob">
              <a
                className="downImg"
                onClick={(e) => {return this.hideDiv()}}
                href="javascript:void(0);"
              ></a>
            </div>
          </div>
        </section>
        <div className="work-video-container">
          <div className="work-video">
            <a
              className="claim-pop effect-main effect-color-hover mobile-claim"
              href="javascript:void(0);"
            >
              {" "}
              Claim Profile
            </a>
            {!localStorage.getItem("isAuthenticated") && <div>
            <a
              title="How Net Pro Referral Works"
              href="https://www.netproreferral.com/how-it-works#video"
              className="howit-banner effect-main"
            >
              <span>how it works</span>
            </a>
              </div>}
          </div>
        </div>
        <Modal
          open={this.state.showLogin}
          center
          showCloseIcon={false}
          classNames={{
            modal: "customModal",
          }}
        >
          <div className="pop-main-inner">
            <div className="login-block login-outer-main">
              <div className="login-icon">
                <div className="icon-img">
                  <div className="icon-inner"></div>
                </div>
              </div>
              <img
                onClick={this.onModalClose}
                className="cross-pop initial loaded"
                alt="Close"
                data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/cross-pop1.png"
                src="https://dcywhuojnzfz0.cloudfront.net/assets/images/cross-pop1.png"
                data-was-processed="true"
              />
              {/* <form> */}
              <div className="login-form">
                <h2>
                  <span className="join-first">Login</span>
                  <span className="join-second">Login With</span>
                </h2>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "7px !important",
                  }}
                  className="with-text"
                >
                  with
                </p>
                <div className="social-login-icons">
                  <a title="Sign In with Facebook" className="slogin logfb">
                    {""}
                  </a>
                  &nbsp;
                  <a title="Sign In with Linkedin" className="slogin loglink">
                    {""}
                  </a>
                  &nbsp;
                  <a title="Sign In with Twitter" className="slogin logtw">
                    {""}
                  </a>
                </div>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "7px !important",
                  }}
                >
                  or
                </p>

                {this.props.loginData.flag &&
                this.props.loginData.data.status === "FAIL" ? (
                  <div className="alert-error">
                    <span className="closebtn" onClick={this.onForgotClose}>
                      &times;
                    </span>
                    {this.props.loginData.data.message}
                  </div>
                ) : null}

                {this.props.loginData.flag &&
                this.props.loginData.data.status === "SUCCESS" ? (
                  <div className="alert-sucess">
                    <span className="closebtn" onClick={this.onForgotClose}>
                      &times;
                    </span>
                    {this.props.loginData.data.message}
                  </div>
                ) : null}

                <div className="full-block">
                  <div className="input-block login-input email-input-div-bg">
                    <input
                      type="email"
                      className="email-icon"
                      aria-label="Email"
                      placeholder="Email"
                      name="loginEmail"
                      id="username"
                      onChange={this.onChange}
                    />
                    <span className="ErrorText">
                      {this.state.formErrors.loginEmail}
                    </span>
                  </div>
                </div>
                <div className="full-block">
                  <div className="input-block login-input pw-input-div-bg">
                    <input
                      type="password"
                      className="key-icon"
                      aria-label="Password"
                      placeholder="Password"
                      name="loginPass"
                      onChange={this.onChange}
                    />
                    <span className="ErrorText">
                      {this.state.formErrors.loginPass}
                    </span>
                  </div>
                </div>
                <input type="hidden" id="segment1" name="segment1" />
                <input type="hidden" id="segment2" name="segment2" />
                <input type="hidden" id="segment3" name="segment3" />
                <input type="hidden" id="segment4" name="segment4" />
                <div className="full-block">
                  <div className="input-block">
                    <div className="check-block">
                      <div className="radio-block">
                        <input
                          type="hidden"
                          name="rememberme"
                          id="rememberme"
                        />
                        <input
                          type="checkbox"
                          name="rememberme1"
                          id="rememberme1"
                          className="radio-cir"
                        />
                        <label htmlFor="rememberme1">Remember me</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="full-block">
                  <div className="input-block">
                    <div className="login-lock">
                      <button
                        type="submit"
                        className="login-btn input-effect"
                        value="Login"
                        onClick={this.onLoginClick}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="forgot-links">
                <a
                  className="forgot-password-link"
                  onClick={() => this.showForgotPopup()}
                >
                  Forgot password?
                </a>
                <a
                  className="register-link"
                  onClick={() => this.showSignupPopup()}
                >
                  Claim a new profile
                </a>
              </div>
              {/* </form> */}
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.claimProfilePopup}
          center
          showCloseIcon={false}
          classNames={{
            modal: "customModal",
          }}
        >
          <div className="pop-main-inner">
            <div
              className="login-block login-outer-main"
              style={{ overflow: "auto" }}
            >
              <img
                style={{ display: "none" }}
                alt="Close"
                id="pop-msg"
                className="cross-pop"
                data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/cross-pop1.png"
              />
              <h2>
                <span> REFER A PROFESSIONAL </span>
              </h2>
              <p>
                Thank you for choosing to refer admin123@yopmail.com. Please
                claim your own profile first.{" "}
              </p>
              <div
                onClick={() => this.claimNewProfile()}
                className="pop-up-btn effect-main effect-color-hover"
              >
                CONTINUE
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.showSignup}
          center
          showCloseIcon={false}
          classNames={{
            modal: "customModal",
          }}
        >
          <div className="pop-main-inner">
            <div className="login-block login-outer-main">
              <div className="login-icon">
                <div className="icon-img">
                  <div className="icon-inner sign-up"></div>
                </div>
              </div>
              <img
                className="cross-pop initial loaded"
                onClick={() => this.onModalClose()}
                alt="Close"
                data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/cross-pop1.png"
                src="https://dcywhuojnzfz0.cloudfront.net/assets/images/cross-pop1.png"
                data-was-processed="true"
              />
              {/* <form > */}
              <input
                type="hidden"
                id="segmentReg2"
                name="segmentReg2"
                value=""
              />
              <input
                type="hidden"
                id="segmentReg1"
                name="segmentReg1"
                value="professionals"
              />
              <div className="login-form reg-error-outer register-div">
                <h2>
                  <span className="join-first">
                    <span className="join-reg-pop">Join</span> Here
                  </span>
                </h2>

                {this.props.registerData.flag &&
                this.props.registerData.data.status === "FAIL" ? (
                  <div className="alert-error">
                    <span className="closebtn" onClick={() => {}}>
                      &times;
                    </span>
                    {this.props.registerData.data.message ||
                      this.props.registerData.data.detailedMessage}
                  </div>
                ) : null}

                {this.props.registerData.flag &&
                this.props.registerData.data.status === "SUCCESS" ? (
                  <div className="alert-sucess">
                    <span className="closebtn" onClick={() => {}}>
                      &times;
                    </span>
                    {this.props.registerData.data.message}
                  </div>
                ) : null}
                <div id="register-message" style={{ display: "none" }}></div>
                <div className="full-block">
                  <div className="input-block input-block fn-input-div-bg">
                    <input
                      type="text"
                      aria-label="First Name"
                      placeholder="First Name"
                      value={this.state.registration.firstName}
                      onChange={(e) => {
                        this.setState({
                          registration: {
                            ...this.state.registration,
                            firstName: e.target.value,
                          },
                        });
                      }}
                      name="firstName"
                      id="us_first_name"
                    />
                    {register && !this.state.registration.firstName && (
                      <span className="ErrorText">Please Enter First Name</span>
                    )}
                  </div>
                </div>
                <div className="full-block">
                  <div className="input-block input-block fn-input-div-bg">
                    <input
                      type="text"
                      aria-label="Last Name"
                      placeholder="Last Name"
                      value={this.state.registration.lastName}
                      onChange={(e) => {
                        this.setState({
                          registration: {
                            ...this.state.registration,
                            lastName: e.target.value,
                          },
                        });
                      }}
                      name="lastName"
                      id="us_last_name"
                    />
                    {register && !this.state.registration.lastName && (
                      <span className="ErrorText">Please Enter last name</span>
                    )}
                  </div>
                </div>
                <div className="full-block">
                  <div for="user_cat" className="input-block selt-aro">
                    <input
                      type="text"
                      aria-label="Profession"
                      placeholder="Profession"
                      value={this.state.registration.profession}
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({
                          registration: {
                            ...this.state.registration,
                            profession: e.target.value,
                          },
                        });
                        this.getProfessionalList();
                      }}
                      name="profession"
                      id="profession"
                    />
                    {register && !this.state.registration.profession && (
                      <span className="ErrorText">
                        Please enter your profession
                      </span>
                    )}
                  </div>
                  {this.state.showProfessionsList && <ul
                  className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content"
                  style={{ marginTop: "48px", width: "300px" }}
                  >
                    {this.state.professionsList.map((item, index) => {
                      return (
                        <div>
                          {item.toLowerCase().includes(this.state.registration.profession.toLowerCase()) && 
                          <li key={item}
                          className="ui-menu-item"
                          onClick={ (e) =>
                            this.userSelect(item)
                          }>
                            {item}
                          </li>}
                        </div>
                      )
                    })}
                  </ul>}
                </div>
                <div className="full-block">
                  <div className="input-block email-input-div-bg">
                    <input
                      type="email"
                      className="email-icon register-email"
                      aria-label="Email Address"
                      placeholder="Email Address"
                      value={this.state.registration.email}
                      onChange={(e) => {
                        this.setState({
                          registration: {
                            ...this.state.registration,
                            email: e.target.value,
                          },
                        });
                      }}
                      name="email"
                      id="email"
                    />
                    {register && !this.state.registration.email && (
                      <span className="ErrorText">
                        Please enter email address
                      </span>
                    )}
                    {register && !this.state.registration.validEmail && (
                      <span className="ErrorText">
                        Please enter valid email address
                      </span>
                    )}
                  </div>
                </div>
                <div className="full-block">
                  <div className="input-block pw-input-div-bg">
                    <input
                      type="password"
                      className="key-icon"
                      aria-label="Password"
                      placeholder="Password"
                      value={this.state.registration.password}
                      onChange={(e) => {
                        this.setState({
                          registration: {
                            ...this.state.registration,
                            password: e.target.value,
                          },
                        });
                      }}
                      name="password"
                      id="password"
                    />
                    {register && !this.state.registration.password &&
                      <span className="ErrorText">Please enter password</span>
                    }
                    {register && this.state.registration.password && !this.state.validPassword && 
                    <div>
                      <span className="ErrorText">Password Must Contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</span>
                      <br />
                    </div>
                    }
                  </div>
                </div>
                <div className="full-block">
                  <div className="input-block pw-input-div-bg">
                    <input
                      type="password"
                      className="key-icon"
                      aria-label="Confirm Password"
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                      value={this.state.registration.confirmPassword}
                      onChange={(e) => {
                        this.setState({
                          registration: {
                            ...this.state.registration,
                            confirmPassword: e.target.value,
                          },
                        });
                      }}
                      name="cpassword"
                      id="cpassword"
                    />
                    {register && !this.state.registration.confirmPassword && (
                      <span className="ErrorText">
                        Please enter confirm password
                      </span>
                    )}
                    {register && this.state.registration.password && 
                    this.state.registration.confirmPassword && !this.state.passwordMatch &&
                    (
                      <span className="ErrorText">
                        Passwords do not match
                      </span>
                    )}
                  </div>
                  <input type="checkbox" onChange={(e) => {
                    if(e.target.checked){
                      this.setState({
                        termsAndConditions: true
                      });
                    } else {
                      this.setState({
                        termsAndConditions: false
                      });
                    }                    
                  }}></input>
                  <NavLink to="/terms" style={{fontSize: '13px'}} onClick={() => {
                      this.onModalClose();
                  }}>&nbsp; &nbsp; Terms and Conditions</NavLink>
                  <br />
                  <small className="ErrorText">{this.state.termsError}</small>
                  <br /> <br />
                </div>
                <div className="full-block signUp-register">
                  <div className="input-block">
                    <div className="login-lock">
                      <button
                        onClick={this.onRegisterSubmit}
                        className="login-btn input-effect"
                        value="Signup"
                        name="signUp"
                        id="signUp"
                      >
                        Signup
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="forgot-links already-acc">
                <a className="login-link" onClick={() => this.showLoginPopup()}>
                  {" "}
                  Already have an account?{" "}
                </a>
              </div>
              {/* </form> */}
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.showForgot}
          center
          showCloseIcon={false}
          classNames={{
            modal: "customModal",
          }}
        >
          <div className="pop-main-inner">
            <div className="login-block login-outer-main">
              <div className="login-icon">
                <div className="icon-img">
                  <div className="icon-inner"></div>
                </div>
              </div>
              <img
                alt="Close"
                onClick={() => {
                  this.setState({ showForgot: false }, () => {
                    this.onForgotClose();
                    this.onModalClose();
                  });
                }}
                className="cross-pop initial loaded"
                data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/cross-pop1.png"
                src="https://dcywhuojnzfz0.cloudfront.net/assets/images/cross-pop1.png"
                data-was-processed="true"
              />
              {/* <form> */}
              {this.props.forgotData.flag &&
              this.props.forgotData.data.status === "FAIL" ? (
                <div className="alert-error">
                  <span className="closebtn" onClick={this.onForgotClose}>
                    &times;
                  </span>
                  {this.props.forgotData.data.message}
                </div>
              ) : null}

              {this.props.forgotData.flag &&
              this.props.forgotData.data.status === "SUCCESS" ? (
                <div className="alert-sucess">
                  <span className="closebtn" onClick={this.onForgotClose}>
                    &times;
                  </span>
                  {this.props.forgotData.data.message}
                </div>
              ) : null}

              <div className="login-form">
                <h2>Forgot Password</h2>
                <div className="outermsg outerdiv">
                  <span className="msg-span" id="response-login-msg-fp"></span>
                  <i className="fa fa-close close className"></i>
                </div>
                <div className="outermsg outerdiv1">
                  <span
                    className="msg-span1"
                    id="response-login-msg1-fp"
                  ></span>
                  <i className="fa fa-close close className1"></i>
                </div>
                <div className="full-block">
                  <div className="input-block login-inp">
                    { !this.state.showVerificationPopUp && 
                    <input
                      type="email"
                      className="email-icon"
                      aria-label="Enter email address"
                      placeholder="Enter email address"
                      name="fogotEmail"
                      id="email-fp"
                      onChange={this.forgotEmailHandler} />
                    }
                    { this.state.showVerificationPopUp && 
                    <div>
                    <input
                      type="number"
                      className="email-icon"
                      aria-label="Enter verification code"
                      placeholder="Enter verification code"
                      name="verificationCode"
                      onChange = {this.verificationCodeHandler}
                      id="verify-fp" />
                    <span className="ErrorText">
                      {this.state.formErrors.verifyCode}
                    </span>
                    <input
                      type="password"
                      className="email-icon"
                      aria-label="Enter new Password"
                      placeholder="Enter new Password"
                      name="newPassword"
                      onChange = {this.newPasswordHandler}
                      id="verify-passcode"
                    />
                    </div> 
                    }
                    <span className="ErrorText">
                      {this.state.formErrors.forgotEmail}
                    </span>
                  </div>
                </div>
                <div className="full-block">
                  <div className="input-block">
                    <div className="login-lock">
                    <br /><br /><br /><br />
                      <button
                        className="login-btn input-effect"
                        name="forget-btn"
                        id="forget-btn"
                        value="Reset Password"
                        onClick={this.onForgotSubmit}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="forgot-links">
                <a className="login-link" onClick={() => this.showLoginPopup()}>
                  Login
                </a>
                <a
                  className="register-link"
                  onClick={() => this.showSignupPopup()}
                >
                  Claim a new profile
                </a>
              </div>
              {/* </form> */}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

Header.propTypes = {};

Header.defaultProps = {};

const mapStateToProps = (state) => ({
  forgotData: state.forgot,
  loginData: state.login,
  registerData: state.register,
});

export default withRouter(
  connect(mapStateToProps, {
    forgotAction,
    forgotReset,
    loginAction,
    registerAction,
  })(Header)
);

// export default withRouter(Header)
