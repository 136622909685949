// auth-service.js
import { API_BASE_URL } from "../../common/index";
import { request } from "../../utils/ApiUtils";
export function submitReferral(referral) {
     
    return request({
        url: API_BASE_URL + '/api/referral',
        method: 'POST',
        data: JSON.stringify(referral)
    });
}

export function editReferral(referral) {
     
    return request({
        url: API_BASE_URL + '/api/referral',
        method: 'PUT',
        data: JSON.stringify(referral)
    });
}

