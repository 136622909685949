import React, { Component } from "react";
import ReactMapboxGl, { Layer, Feature, Marker, Popup, ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import './index.css';
import Search from "../Search/Search";
import {Link} from 'react-router-dom';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_API_KEY
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referralData: props.location.state.data,
      referralDataLng: [""],
      referralDataLat: [""],
      searchText: props.location.state.searchText,
      location: props.location.state.location,
      latitude: null,
      longitude: null,
      popUp: false,
      popUpMarker: {
        item: null,
        index: null
      }
    };
  }

  createMapCoordinates = () => {
    let latData = [];
    let lngData = [];
    this.state.referralData.forEach(item => {
      lngData.push(Number(item._source.longitude));
      latData.push(Number(item._source.latitude));
    });
    this.setState({
      referralDataLng: [...lngData],
      referralDataLat: [...latData]
    });
  }
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getCoordinates,
        this.handleLocationError
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  getCoordinates = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  };

  displayPopUp = (item, index) => {
    this.setState({
      popUp: true,
      popUpMarker: {
        item: item,
        index: index
      }
    });
  }

  hidePopUp = (item, index) => {
    this.setState({
      popUp: false,
      popUpMarker: {
        item: item,
        index: index
      }
    });
  }

  handleLocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        alert("An unknown error occurred.");
        break;
    }
  };

  navigatationHandler = (e, data) => {
    this.props.history.push({
      pathname: `/searchResults/${data._id}`,
      state: {
        data: data
      }
    });
  }
  
  searchDataHandler = (data) => {
    this.setState({
      referralData: data.referralData,
      searchText: data.searchText,
      location: data.location
    });
    this.createMapCoordinates();
  }

  componentDidMount() {
    this.createMapCoordinates();
    this.getLocation();
  }
  render() {
    return (
      <div>
        <div class="heading-block">
          <div class="wrapper">
            <div class="heading-content-left professional srch-hds">
              <h1>{this.state.searchText} in {this.state.location}</h1>
              <span>About {this.state.referralData.length} Results</span>
            </div>
            <div class="heading-content-right professional">
              <div>
                <a href="https://www.netproreferral.com/" class="active">
                  Home
                </a>
                <span>&nbsp;&gt;&nbsp;</span>
                <a href="">Search</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{paddingTop: "350px"}}>
            <Search getSearchData={this.searchDataHandler}></Search>
          </div>
          <div className="row align-items-start">
            &nbsp; &nbsp; 
            <div className="col">
              <Map      
              style="mapbox://styles/mapbox/streets-v9"
              containerStyle={{
                height: '70vh',
                width: '70vw'
              }}
              center={[this.state.referralDataLng[0], this.state.referralDataLat[0]]}
              zoom={[7]}
              renderChildrenInPortal={true}
            >
              <ZoomControl/>
              
                <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                  <Feature coordinates={[-77.0364, 38.8951]} />
                </Layer>

                {this.state.referralDataLng.map((item, index) => 
                  <Marker key={index} coordinates={[item, this.state.referralDataLat[index]]} offsetLeft={-24} offsetTop={-48}
                          onMouseEnter={(e) => this.displayPopUp(item, index)} onMouseLeave={(e) => this.hidePopUp(item, index)}>
                    <img src="https://img.icons8.com/color/48/000000/marker.png" />
                  </Marker>
                )}

                {this.state.popUp &&
                  <Popup
                    coordinates={[this.state.popUpMarker.item, this.state.referralDataLat[this.state.popUpMarker.index]]}
                    offset={{
                      'bottom-left': [12, -38],  'bottom': [0, -38], 'bottom-right': [-12, -38]
                    }}>
                    <ol>
                      <img src={this.state.referralData[this.state.popUpMarker.index]._source.images[0]}></img>
                      <li>{this.state.referralData[this.state.popUpMarker.index]._source.name}</li>
                      <li>{this.state.referralData[this.state.popUpMarker.index]._source.speciality}</li>
                      <li>{this.state.referralData[this.state.popUpMarker.index]._source.address}</li>
                    </ol>
                  </Popup>
                  }
              </Map>
            </div>
            <div className="col custom-list">
              <ul>
                {this.state.referralData.map(item => (
                  <ol key={item._source.id}>
                    <li><img src={item._source.images[0]}></img></li>
                    <Link href="javascript:void(0)" onClick={(e) => {this.navigatationHandler(e, item)}}>{item._source.name}</Link>
                    <li><i>{item._source.speciality}</i></li>
                    <li>{item._source.address}</li>
                    <hr></hr>
                  </ol>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
