import React from 'react'
import './SeoAuditorIndex.css';

const SeoAuditorIndex = () => {
      
    return (
        <div>
            <div className="auditreportmain ">

<div className="seo-audit-banner" style={ {backgroundImage: "url(https://www.netproreferral.com/assets/images/seo-banner-bg.jpg)"} }>
    <div className="wrapper">
        <h1 className="seo-banner-heading">WEBSITE SEO ANALYSIS &amp; AUDIT TOOL</h1>
        <h3 className="seo-banner-subheading"> Enter your website's URL to check your SEO score and download a report on how to improve the score!</h3>
        <div className="seo-top-search">
            <form method="post" id="seo_frm" className="seo_frm" name="seo_frm">
                <div className="seo-search-inner">
                    <input style={ {width : '68%' , height : '60px' , background : 'white' } } name="seo_url" type="text" title="Enter URL" placeholder="http://" />
                    <div className="try-free-cover effect-main effect-color-hover"><input type="submit" name="submitBtn" value="TRY IT FOR FREE" /></div>
                </div>
            </form>
        </div>
                        <div className="sudit-banner-img">
                <img alt="" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-banner-img.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-banner-img.png" data-was-processed="true" />
                <span className="learn-more-btn effect-main effect-wht-hover">Learn More<img alt="" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/downmove.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/downmove.png" data-was-processed="true" /></span>
            </div>
                </div>
</div>
<div className="audit-tool-designed">
    <div className="wrapper">
        <div className="tool-designed-row">
            <div className="designed-row-grid">
                <h2 className="designed-row-heading">The One SEO Audit Tool Designed<br /> for Everyone</h2>
                <div className="designed-box-cover">
                    <div className="designed-box-main">
                        <div className="designed-box-height">
                            <div className="designed-box-middile">
                                <figure>
                                    <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design1.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design1.png" data-was-processed="true" />
                                </figure>
                                <p>Individual Professionals</p>
                            </div>
                        </div>
                    </div>
                    <div className="designed-box-main">
                        <div className="designed-box-height">
                            <div className="designed-box-middile">
                                <figure>
                                    <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design2.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design2.png" data-was-processed="true" />
                                </figure>
                                <p>Business Owners</p>
                            </div>
                        </div>
                    </div>
                    <div className="designed-box-main">
                        <div className="designed-box-height">
                            <div className="designed-box-middile">
                                <figure>
                                    <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design3.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design3.png" data-was-processed="true" />
                                </figure>
                                <p>SEO Experts</p>
                            </div>
                        </div>
                    </div>
                    <div className="designed-box-main">
                        <div className="designed-box-height">
                            <div className="designed-box-middile">
                                <figure>
                                    <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design4.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design4.png" data-was-processed="true" />
                                </figure>
                                <p>Digital Marketers</p>
                            </div>
                        </div>
                    </div>
                    <div className="designed-box-main">
                        <div className="designed-box-height">
                            <div className="designed-box-middile">
                                <figure>
                                    <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design5.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design5.png" data-was-processed="true" />
                                </figure>
                                <p>Bloggers</p>
                            </div>
                        </div>
                    </div>
                    <div className="designed-box-main">
                        <div className="designed-box-height">
                            <div className="designed-box-middile">
                                <figure>
                                    <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design6.png" className="initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/design6.png" data-was-processed="true" />
                                </figure>
                                <p>Webmasters</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="designed-row-grid analysis-tool">
                 <h2 className="designed-row-heading">Advantages of using this <br /> SEO Analysis Tool </h2>
                 <div className="designed-box-cover">
                    <ul className="analysis-tool-list">
                        <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/analysis-bg.png" className="img-left-tool initial loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/analysis-bg.png" data-was-processed="true" />
                        <li>Current SEO health</li>
                        <li>Instant Analysis</li>
                        <li>Code level improvements</li>
                        <li>Content improvements</li>
                        <li>Improve user experience</li>
                        <li>Competitor Analysis</li>
                        <li>Get an action plan</li>
                    </ul>
                </div>
            </div>
            <div className="audit-button">
                <a className="analyze-btn effect-main effect-color-hover" >I Want To Try</a>
            </div>
        </div>
    </div>
</div>
{/* end of seconf section */}

<div className="seo-full-section seo-score">
    <div className="wrapper">
        <div className="seo-full-inner">
            <div className="seo-full-left">
                <h2 className="seo-full-heading">One SEO Score for your Website</h2>
                <p>NetProReferral&nbsp;SEO&nbsp;Audit Tool calculates a single score to evaluate your overall website SEO performance. This comprehensive and precise website SEO score will instantly tell you how your website is doing in terms of SEO and how well you have optimized your website structure to make it search engine friendly.</p>
                <p>Along with this&nbsp;all-inclusive SEO score, the NetProReferral SEO audit tool will give you&nbsp;a detailed&nbsp;SEO analysis report. So, you will be able to make all the necessary improvements to your website and&nbsp;turn your score&nbsp;into&nbsp;a stunning one!</p>
                <div className="audit-button">
                    <a className="analyze-btn effect-main effect-wht-hover" >Try It Now</a>
                </div>
            </div>
            <div className="seo-full-right">
                <img alt="NetProReferral" className="analyze-btn loaded" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full1.png" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full1.png" data-was-processed="true" />
            </div>
        </div>
    </div>
</div>

<div className="seo-full-section seo-analyze-issue">
    <div className="wrapper">
        <div className="seo-full-inner">
            <div className="seo-full-left">
                <h2 className="seo-full-heading">Instantly analyze SEO issues and get recommendations</h2>
                <p>With our fast and accurate NetProReferral SEO analysis tool, you get to analyze your website’s SEO issues instantly and get detailed guidelines to improve your SEO performance for free.</p>
                <p>The NetProReferral SEO audit tool crawls within your whole web structure and produces an inclusive report on all the website SEO points. From this detailed and free SEO analysis report, you will be able to easily understand how you are doing in each and every&nbsp;aspect of&nbsp;SEO&nbsp;and how you can improve your search engine performance.</p>
                <div className="audit-button">
                    <a className="analyze-btn effect-main effect-wht-hover"  >Try It Now</a>
                </div>
            </div>
            <div className="seo-full-right">
                <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full2.png" className="loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full2.png" data-was-processed="true" />
            </div>
        </div>
    </div>
</div>

<div className="seo-full-section seo-save-hour">
    <div className="wrapper">
        <div className="seo-full-inner">
            <div className="seo-full-left">
                <h2 className="seo-full-heading">Save hours with On-Page SEO checker</h2>
                <p>An SEO specialist usually has to spend hours to check every critical SEO aspects of each and every web page and then manually analyze the indicators to provide an action plan for improvements.&nbsp;This process needlessly eats up massive amount of working hours from their schedule.</p>
                <p>But with our&nbsp;free&nbsp;SEO audit tool&nbsp;from NetProReferral,&nbsp;you can save&nbsp;a&nbsp;huge amount of time and money and get a more accurate SEO report for your website in minutes.</p>
                <div className="audit-button">
                    <a className="analyze-btn effect-main effect-wht-hover" >Try It Now</a>
                </div>
            </div>
            <div className="seo-full-right">
                <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full3.png" className="loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full3.png" data-was-processed="true" />
            </div>
        </div>
    </div>
</div>

 <div className="seo-full-section seo-modern-looking">
    <div className="wrapper">
        <div className="seo-full-inner">
            <div className="seo-full-left">
                <h2 className="seo-full-heading">MODERN AND EASY TO UNDERSTAND REPORTS IN PDF</h2>
                <p>The&nbsp;website&nbsp;SEO analysis report that you get from the NetProReferral SEO analyzer is very easy to understand. You do not have to be an SEO expert to get the action points and the recommendations. You can carry out all the instructions by yourself for improving the SEO performance of your site.</p>
                <p>All the details are clearly pointed out in a modern and clutter free SEO report in PDF format that allows to you see the whole picture with clarity and&nbsp;easy to share with your friends, colleagues or developer.</p>
                <div className="audit-button">
                    <a className="analyze-btn effect-main effect-wht-hover">Try It Now</a>
                </div>
            </div>
            <div className="seo-full-right">
                <img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full4.png" className="loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/seo-full4.png" data-was-processed="true" />
            </div>
        </div>
    </div>
</div>

<div className="why-npr-cover" style={ {backgroundImage: "url(https://www.netproreferral.com/assets/images/npr-bg-img.jpg)"} }>
    <div className="wrapper">
        <div className="npr-content">
            <h2 className="designed-row-heading">Why NetProReferral SEO Audit Tool</h2>
            <p>NetProReferral SEO Audit Tool is essential for every&nbsp;kind of website SEO maintenance, upgrading and troubleshooting. If you have a website and want to keep your SEO constantly improving, then&nbsp;this&nbsp;free SEO Audit Tool&nbsp;is for you. Whether you have a company website, personal blog or an e-commerce site NetProReferral&nbsp;SEO analysis&nbsp;tool&nbsp;provides fitting SEO analysis and recommendations to each and every kind of web-structure.</p>
            <p>This free on-page SEO checker is suitable for anybody who has a website or working on one. This single tool is packed with power to help Individual professionals, bloggers, SEO Specialist, webmasters, business owners and digital marketers alike.</p>
            <p>NetProReferral SEO Audit Tool analyzes all the SEO points of your website and provides you with an easy to understand, clear and precise website SEO analysis report along with your overall SEO&nbsp;performance&nbsp;score. You get to implement the recommendations given in the report and improve your SEO score very easily and instantly.</p>
            <a className="npr-button analyze-btn effect-main effect-color-hover">Analyze Your Website Like a PRO!<img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/btn-arow.png" className="loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/btn-arow.png" data-was-processed="true" /></a>
        </div>
    </div>
</div>
 {/* end of npr section  */}

<div className="seo-analyzer-cover">
    <div className="wrapper">
         <h2 className="designed-row-heading">Our SEO Analyzer crawls and analyzes your website for<br /> in-depth On-Page SEO checks</h2>
         <h4 className="subhead-seo">Quick and simple SEO audit of any website.</h4>
         <div className="seo-analyzer-row">
             <div className="seo-analyzer-box analyzer-content" style={ {backgroundImage: "url(https://www.netproreferral.com/assets/images/analyzer1.png)"} }>
                 <h3 className="analyzer-box-heading">Content</h3>
                 <ul>
                     <li>Content Quality</li>
                     <li>Content Keyword Density</li>
                     <li>Content Keyword Optimization</li>
                     <li>Duplicate Content (Coming soon...)</li>
                     <li>Image ALT attributes quality</li>
                     <li>Inappropriate internal anchor texts</li>
                     <li>Heading tags</li>
                 </ul>
             </div>
             <div className="seo-analyzer-box analyzer-structure" style={ {backgroundImage: "url(https://www.netproreferral.com/assets/images/analyzer2.png)"} } > 
                 <h3 className="analyzer-box-heading">Site Structure</h3>
                 <ul>
                     <li>Quality and quantity of internal links</li>
                     <li>Number of external links</li>
                     <li>Broken internal or external links</li>
                     <li>hreflang attributes</li>
                     <li>Language codes for multilingual sites</li>
                 </ul>
             </div>
             <div className="seo-analyzer-box analyzer-data" style={ {backgroundImage: "url(https://www.netproreferral.com/assets/images/analyzer3.png)"} } >
                 <h3 className="analyzer-box-heading">STRUCTURED DATA</h3>
                 <ul>
                     <li>Missing structured data</li>
                     <li>Invalid structured data markup codes</li>
                     <li>Length of Open Graph title tags</li>
                     <li>Open Graph title tag functionality</li>
                     <li>Length and functionality of Open Graph description tags</li>
                 </ul>
             </div>
             <div className="seo-analyzer-box analyzer-crawler" style={ {backgroundImage: "url(https://www.netproreferral.com/assets/images/analyzer4.png)"} } >
                 <h3 className="analyzer-box-heading">Crawler</h3>
                 <ul>
                     <li>URL performance and functionality errors</li>
                     <li>Missing image ALT attributes</li>
                     <li>CSS and&nbsp;Javascript</li>
                     <li>robot.txt errors</li>
                     <li>Canonicalization Errors</li>
                     <li>Inactive&nbsp;redirects</li>
                 </ul>
             </div>
             <a className="npr-button analyze-btn effect-main effect-color-hover">Analyze Your Website Like a PRO!<img alt="NetProReferral" data-src="https://dcywhuojnzfz0.cloudfront.net/assets/images/btn-arow.png" className="loaded" src="https://dcywhuojnzfz0.cloudfront.net/assets/images/btn-arow.png" data-was-processed="true" /></a>
         </div>
    </div>
</div>


<div className="bad-reviews-cover">
    <div className="wrapper">
        <h2 className="designed-row-heading">FED UP WITH FAKE, BIASED AND BAD REVIEWS, REGISTER ON<br /> NetProReferral FOR GENUINE REFERRALS</h2>
        <p>Gone are the days of fretting about fake, biased and intentionally negative reviews!</p>
        <p>With&nbsp;NetProReferral, you don’t have to be the victim of the faulty reviewing systems anymore. Just register on&nbsp;NetProReferral and harvest the benefits of a completely expert referral based professional network.</p>
        <p>As a professional expert, you will be able to get connected with your peers, mentors, industry leaders and all the other professionals related to your field and will be able&nbsp;to&nbsp;get referrals from them on&nbsp;NetProReferral. As a result, professional help seekers can easily get to know you and your practice more professionally and objectively, instead of getting a wrong and vague idea from reading online&nbsp;reviews about your practice.</p>
        <div className="audit-button">
            <a className="effect-main effect-wht-hover" target="_blank" href="https://www.netproreferral.com/how-it-works">How It Works</a>
        </div>
    </div>
</div>
<script type="text/javascript">$('.btn-orange-main').hide();</script>
</div>



        </div>
    )
}

export default SeoAuditorIndex
