import { API_BASE_URL } from "../../common/index";
import { request } from "../../utils/ApiUtils";

export function getInvitationByToken(token) {
    return request({
        url: API_BASE_URL + "/api/invitation/findbytoken?token="+token,
        method: 'GET'
    });
}

export function getReferenceById(id) {
    return request({
        url: API_BASE_URL + "/api/referral/"+id,
        method: 'GET'
    });
}

export function getUserById(id) {
    return request({
        url: API_BASE_URL + "/api/user/get/byid?id="+id,
        method: 'GET'
    });
}

export function getRefererenceByInventaionToken(token) {
    return request({
        url: API_BASE_URL + "/api/referral/findBytoken?token="+token,
        method: 'GET'
    });
}
