import React, { Component, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import Notify, { AlertTypes } from "../../utils/AlertNotification";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
     
    }
    this.Loader = React.createRef();
  }
  onChange = (e) => {

    const { name, value } = e.target;
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() { 
  
  }
  handleValidation(fields) {
    let errors = {};
    let formIsValid = true;

    if (!fields["newPass"]) {
      formIsValid = false;
      errors["newPass"] = "New Password cannot be empty";
    }
    if (!fields["oldPass"]) {
      formIsValid = false;
      errors["oldPass"] = "Old Password cannot be empty";
    }
    if (!fields["confirmPass"]) {
      formIsValid = false;
      errors["notMatch"] = "Confirm Password cannot be empty";
    }

    if (typeof fields["newPass"] !== "undefined") {
      let filter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (filter.test(fields["newPass"])) {
        formIsValid = true;
      } else {
        formIsValid = false;
        errors["newPass"] = "Password Must Contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
      }
      if (!(fields["confirmPass"] == fields["newPass"])) {
        formIsValid = false;
        errors["notMatch"] = "Password Not Match";
      }

      else {
        formIsValid = true;
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  notifySuccess = () => {
    Notify.sendNotification('Success from Home!', AlertTypes.success);
  }

  resetPassword = async () => {
    const apiUrl = 'https://8gsdr56bhd.execute-api.us-west-2.amazonaws.com/dev/changePassword';
    
    
    const payload = {
      email: localStorage.getItem("email"),
      oldPass: this.state.old_password,
      newPass: this.state.new_password,
      confirmPass: this.state.re_password
    }

    
    if (this.handleValidation(payload)) {
      try {
        
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(payload)
        });

        if(!response.ok) {
          throw new Error("Some server error has occurred");
        }

        const data = await response.json();

        if(data.success){
          Notify.sendNotification(data.message, "success");
          this.props.history.push('/profile');
        } else {
          Notify.sendNotification(data.message, "error");
        }
      } catch (error) {
        Notify.sendNotification(error, 'errror');
      }
    }
  };

  render() {
    return (
      <div
        className="add-studio-frm edit-profile"
        name="change-form"
        id="change-form"
        noValidate="novalidate"
        >
       
     
        <div className="basic-info">
          <div className="field-strem">
            <div className="full">
              <div className="stream-heading">
                Old Password<span style={{ color: "red" }}>*</span>
              </div>
              <div className="profile-input-block ">
                <input
                  type="Password"
                  className="block-input .login-inp"
                  name="old_password"
                  id="old_password"
                  value={this.state.old_password || ""}
                  onChange={this.onChange}
                  title="Enter your old password"
                  placeholder="Enter your old password"
                  />
                <span className="error">{this.state.errors["oldPass"]}</span>
              </div>
            </div>
          </div>
          <div className="field-strem">
            <div className="full">
              <div className="stream-heading">
                New Password<span style={{ color: "red" }}>*</span>
              </div>
              <div className="profile-input-block ">
                <input
                  type="Password"
                  className="block-input .login-inp"
                  id="new_password"
                  value={this.state.new_password || ""}
                  onChange={this.onChange}
                  name="new_password"
                  title="Enter your new password"
                  placeholder="Enter your new password"
                  />
                  <span className="error">{this.state.errors["newPass"]}</span>
              </div>
            </div>
          </div>
          <div className="field-strem">
            <div className="full">
              <div className="stream-heading">
                Confirm Password<span style={{ color: "red" }}>*</span>
              </div>
              <div className="profile-input-block ">
                <input
                  type="Password"
                  className="block-input .login-inp"
                  name="re_password"
                  id="re_password"
                  title="Confirm your new password"
                  placeholder="Confirm your new password"
                  value={this.state.re_password || ""}
                  onChange={this.onChange}
                  />
                  <span className="error">{this.state.errors["notMatch"]}</span>
              </div>
            </div>
          </div>
          <div className="btn-block">
            <button
              style={{ fontSize: "16px" }}
              className="btn-main-link active effect-main effect-color-hover"
              id="save"
              onClick={this.resetPassword}
              >
              UPDATE PASSWORD
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = {};

ChangePassword.defaultProps = {};

export default withRouter(ChangePassword);
