import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router-dom";
import { parse } from "query-string";
import { fetchUserAction, uploadProfilePicture, getProfilePictureByUser } from "./logic";
import User from "../user";
import { getActiveUser } from "../../common/commonService";
import ReactModal from "react-modal";
import swal from 'sweetalert';

import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import ReferralsList from "../referralList";
import ChangePassword from "../changePassword";
import Referred from "../referred";
import Message from "../message"
import Profile from "../profile"
import editprofile from "../editprofile"
import Notify from "../../utils/AlertNotification";

const styleObj = {
  padding: "10px",
  margin: "10px",
};

class DashBoard extends Component {
  constructor(props) {
    super(props);
    const q = parse(this.props.location.search);
    this.state = {
      query: q.q || "",
      showModal: false,
      imagePreviewUrl: 'https://dcywhuojnzfz0.cloudfront.net/assets/images/noprofileimage.jpg',
      userProfilePicture : '',
      isTokenValid: false,
      activeModal: 'Dashboard',
      firstName: '',
      lastName: ''
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this._getProfilePicture = this._getProfilePicture.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  displaySweetAlert() {
    swal({
      text: "Account activation is required. Please check your email for account activation link.",
      icon: "warning",
      buttons: false,
      dangerMode: true,
    });
  }
  verifyUserProfile = () => {
    if(localStorage.getItem('token')) {
      this.setState({isTokenValid: true});
    } else {
      this.setState({isTokenValid: false});
    }
  }

  verifyProfilePicture = () => {
    localStorage.getItem("userImage") && this.setState({imagePreviewUrl: localStorage.getItem("userImage")});
  }
  
  componentDidMount() {

    if(localStorage.getItem("firstname") !== null) {
      this.setState({
        firstName: localStorage.getItem('firstname'),
        lastName: localStorage.getItem('lastname')
      });
    }
    this.verifyUserProfile();
    if(this.props.location.hash === '#/profile') {
      this.setState({activeModal: 'My Profile'})
    }

    getActiveUser()
      .then((response) => {
        localStorage.setItem("ActiveUser", JSON.stringify(response.data));
        this.setState({ firstName: response.data.firstName })
        this.setState({ lastName: response.data.lastName })
        this._getProfilePicture();
      })
      .catch((error) => {

      });
    if (this.state.query) {
      this.props.fetchUserAction({ q: this.state.query });
    }

    this.verifyProfilePicture();
  }

  _getProfilePicture() {
    let user = JSON.parse(localStorage.getItem("ActiveUser"));
    getProfilePictureByUser(user.id)
        .then((response) => {
          if ((response.data.status = "SUCESS")) {
            this.setState({
              userProfilePicture: response.data.data.base64Img
            });
          }
        })
        .catch((error) => {
          if (error.status === 401) {
          } else {
          }
        });
  }

  async _handleImageChange(e) {

    let reader = new FileReader();
    let file = e.target.files[0];
    
    reader.onloadend = async () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    
      this.handleCloseModal();

    if(file != undefined){
      if(!!file.type.match(/image.*/)){
        try {
          const response = await fetch('https://8gsdr56bhd.execute-api.us-west-2.amazonaws.com/dev/uploadProfilePicture', {
            method: "POST",
            body: JSON.stringify(
              {
                imageData: reader.result,
                id: localStorage.getItem('id')
              }
            )
          });

          if(!response.ok) {
            throw new Error("Some server error has occurred");
          }

          const data = await response.json();
          
          if(data.success) {
            Notify.sendNotification(data.message, 'success');
          } else {
            Notify.sendNotification(data.message, 'error');
          }
          
        } catch (error) {
          Notify.sendNotification(error, 'error');
        }
      }
      else {
        alert("Not image File..")
      }
    }
  else {
    alert("No file selected")
  }
  }
  reader.readAsDataURL(file);
}

  componentWillReceiveProps(nextProps) {
    const oldQuery = parse(this.props.location.search);
    const newQuery = parse(nextProps.location.search);
    if (oldQuery.q !== newQuery.q) {
      this.setState(
        {
          query: newQuery.q,
        },
        () => {
          this.props.fetchUserAction({ q: this.state.query });
        }
      );
    }
  }

  renderList() {
    if (this.props.gene.flag) {
      return this.props.gene.data.map((item, index) => (
        <User key={index} item={item} />
      ));
    }
    return null;
  }
  render() {
    let {imagePreviewUrl, userProfilePicture, user} = this.state;
    let $imagePreview = null;
    user = JSON.parse(localStorage.getItem("ActiveUser"));
    if(userProfilePicture != ''){
      let imageSrc = "data:image/png;base64, "+userProfilePicture;
      $imagePreview = (<img src={imageSrc} />);
    }else{
      if (imagePreviewUrl) {
        $imagePreview = (<img src={imagePreviewUrl} />);
      }
    }

    return (
      <HashRouter>
        <div style={styleObj}>
          <section className="my-profile">
            <div className="wrapper">
              <div className="heading-block">
                <div className="heading-content-left">
                  <h1>
                    {this.state.activeModal}
                    <span>View your professional information here</span>
                  </h1>
                </div>
                <div className="fb-invite-pop">
                  <a
                    className="effect-main effect-fb-hover"
                    id="FacebookInviteFriends"
                  >
                    Invite your friends
                </a>
                </div>
                <div className="heading-content-right">
                  <NavLink to={"/"} className="active" onClick={(e) => {this.setState({activeModal: 'Dashboard'})}}>
                    Home{" "}
                  </NavLink>
                  <NavLink to={this.state.isTokenValid && "/profile"} onClick={(e) => {this.setState({activeModal: 'My Profile'}); !this.state.isTokenValid && this.displaySweetAlert()}}>My Profile</NavLink>
                </div>
              </div>

              <link
                rel="stylesheet"
                type="text/css"
                href="https://dcywhuojnzfz0.cloudfront.net/assets/css/sweetalert.css"
                media="all"
                onload="if(media!=='all')media='all'"
              />
              <div className="account-page-container new-account-container">
                <div className="wrapper">
                  <div className="acc-main-block-out innerpagestemplate">
                    <div>
                      <div className="ref_message"></div>
                    </div>
                    <div className="acc-main-block">
                      <link
                        href="https://www.netproreferral.com/assets/jcrop/jquery.Jcrop.min.css"
                        rel="stylesheet"
                        type="text/css"
                        media="all"
                        onload="if(media!=='all')media='all'"
                      />
                      <script
                        defer=""
                        type="text/javascript"
                        src="https://www.netproreferral.com/assets/jcrop/jquery.Jcrop.min.js"
                      ></script>
                      <div className="edit-profile-content-block">
                        <div className="sidebar-profile">
                          <div className="profile-pic-block">
                            <div className="profile-pic1" onClick={this.state.isTokenValid ? this.handleOpenModal: this.displaySweetAlert}>
                              {$imagePreview}
                              <div
                                title="Add Picture"
                                id="show_pic_popup"
                                style={{ cursor: "pointer" }}
                                className="edit-overlay-img"
                              ></div>
                            </div>

                            <ReactModal
                              isOpen={this.state.showModal}
                              contentLabel="Minimal Modal Example"
                              className="Modal"
                              overlayClassName="Overlay"
                              onRequestClose={this.handleCloseModal}
                            >
                              <div className="popup" id="popup" style={{ display: 'block' }}>
                                <div className="popup-inner">
                                  <div className="popup-tb">
                                    <div className="popup-cell">
                                      <div className="crop-popup-main">
                                        <div className="crop-popup" style={{ maxHeight: '528px' }}>
                                          <h3 className="prof-pic-chnge head1"> Change picture</h3>
                                          <div className="browse-btn">
                                            <input type="file"  name="profilePic"  className="browse-btn-a file-browse" onChange={this._handleImageChange} />
                                            <a  id="profilePic-btn" className="browse-btn-a"> Browse image </a>
                                            
                                          </div>
                                          <div style={{ display: 'none' }} className="img-loader progress-bar-pop img-loader">
                                            <div className="progress-bar-gray">
                                              <div className="progress-bar-move" />
                                            </div>
                                          </div>
                                          <div id="profile-picture-crop-panel" className="uploaded-img" style={{ display: 'none' }} />
                                          <div id="cnfrm-img-remove" style={{ display: 'none' }}>
                                            <h3 className="prof-pic-chnge head2"> Remove picture</h3>
                                            <div className="del-btn cnfrm-rem" style={{ display: 'block' }}><a className="browse-btn-a effect-main effect-color-hover" id="remUPImage" href="javascript:void(0)"> Confirm </a></div>
                                            <div className="del-btn cncl-rem" style={{ display: 'block' }}><a className="browse-btn-a effect-main effect-color-hover" id="cancelDel" href="javascript:void(0)"> Cancel </a></div>
                                          </div>
                                          <div style={{ color: 'red', fontSize: '13px' }} id="img-msg" />
                                        </div>
                                        <a className="cross imge"><img alt="Close"  onClick={this.handleCloseModal} src="https://dcywhuojnzfz0.cloudfront.net/assets/images/multiply.png" className="initial loading" data-was-processed="true" /></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </ReactModal>
                            <h2>Dear Member</h2>
                            <h6>{this.state.firstName} {this.state.lastName}</h6>
                            <div className="inviteRef"></div>
                          </div>
                          <ul>
                            <li id="go-to-msg1" className={window.location.href.indexOf("/profile") > -1 ? 'activeli' : ''} >
                              <NavLink exact activeClassName="active" to={this.state.isTokenValid && "/profile"} onClick={(e) => {this.setState({activeModal: 'My Profile'}); !this.state.isTokenValid && this.displaySweetAlert()}}>My Profile</NavLink>
                            </li>
                            <li className={window.location.href.indexOf("/editprofile") > -1 ? 'activeli' : ''}>
                              <NavLink exact activeClassName="active" to={this.state.isTokenValid && "/editprofile"} onClick={(e) => {this.setState({activeModal: 'Edit Profile'}); !this.state.isTokenValid && this.displaySweetAlert()}}>Edit Profile</NavLink>
                            </li>
                            <li className={window.location.href.indexOf("/changePassword") > -1 ? 'activeli' : ''}>
                              <NavLink exact activeClassName="active" to={this.state.isTokenValid && "/changePassword"} onClick={(e) => {this.setState({activeModal: 'Change Password'}); !this.state.isTokenValid && this.displaySweetAlert()}}>Change Password</NavLink>
                            </li>
                            <li id="get-ref-trip" className={window.location.href.indexOf("/referred") > -1 ? 'activeli' : ''}>
                              <NavLink exact activeClassName="active" to={this.state.isTokenValid && "/referred"} onClick={(e) => {this.setState({activeModal: 'Get Referred'}); !this.state.isTokenValid && this.displaySweetAlert()}}>Get Referred</NavLink>

                              <div className="notify-after-rev">
                                <span id="go-to-msg">
                                  You have 0 referral(s). Would you like to ask
                                  for referrals?
                                <br />
                                </span>
                                <img
                                  alt="NetProReferral"
                                  src="https://dcywhuojnzfz0.cloudfront.net/assets/images/line2.png"
                                  className="initial loading"
                                  data-was-processed="true"
                                />
                              </div>
                            </li>

                            <li className={window.location.href.indexOf("/referralList") > -1 ? 'activeli' : ''}>
                              <NavLink exact activeClassName="active" to={this.state.isTokenValid && "/referralList"} onClick={(e) => {this.setState({activeModal: 'Referrals List'}); !this.state.isTokenValid && this.displaySweetAlert()}}>Referrals List</NavLink></li>
                            <li className={window.location.href.indexOf("/message") > -1 ? 'activeli' : ''}>
                              <NavLink exact activeClassName="active" to={this.state.isTokenValid && "/message"} onClick={(e) => {this.setState({activeModal: 'Message'}); !this.state.isTokenValid && this.displaySweetAlert()}}>Message</NavLink>
                            </li>
                          </ul>
                          <a id="dlt-prof">Deactivate your account</a>
                        </div>
                        <div className="popup" id="popup" style={{ display: "none" }}>
                          profile pic upload pic
                      </div>


                        <div className="content-profile-block">


                          <div className="content">
                            <Route path="/referralList" component={ReferralsList} />
                            <Route path="/changePassword" component={ChangePassword} />
                            <Route path="/referred" component={Referred} />
                            <Route path="/editprofile" component={editprofile} />
                            <Route path="/message" component={Message} />
                            {window.location.href.includes("dashboard")&&
                            window.location.href.includes("profile")&&
                            !window.location.href.includes("edit")&&
                            <Route path="/" component={Profile} />
                            }
                            
                          </div>

                          <div className="basic-info" style={{ marginTop: "20px" }}>
                            <div className="basic-info-heading advertiseYou-block">
                              <h1 style={{fontSize: "26px"}}>Advertise yourself on</h1>
                              <p className="advertise-social">
                                <a
                                  title="Facebook"
                                  className="fb-adver"
                                  onclick="sharePop('Facebook','add link here');"
                                  alt=""
                                >
                                  {""}
                                </a>
                              &nbsp;
                              <a
                                  title="Linkedin"
                                  className="lin-adver"
                                  onclick="sharePop('Linkedin','add link here');"
                                  alt=""
                                >
                                  {""}
                                </a>
                              &nbsp;
                              <a
                                  title="Twitter"
                                  className="twi-adver"
                                  onclick="sharePop('Twitter','add link here');"
                                  alt=""
                                >
                                  {""}
                                </a>
                              &nbsp;
                              <a
                                  title="Google"
                                  className="goggle-adver"
                                  onclick="sharePop('Google','add link here');"
                                  alt=""
                                >
                                  {""}
                                </a>
                              &nbsp;&nbsp;
                            </p>
                              <a title="Get Referred" className="moreRefMp">
                                {""}
                              </a>
                            </div>

                            <p>
                              <span
                                style={{
                                  fontSize: "13px",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                }}
                              >
                                Public profile URL:
                                add some link here later
                            </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </HashRouter>
    );
  }
}

DashBoard.propTypes = {
  geneAction: PropTypes.func,
  gene: PropTypes.object.isRequired,
};

DashBoard.defaultProps = {};

const mapStateToProps = (state) => ({
  gene: state.gene,
});

export default withRouter(
  connect(mapStateToProps, { fetchUserAction })(DashBoard)
);
