import React, { useState } from "react";

function addUser(props) {
  const [fieldList, setfieldList] = useState([{ ref_name: "", ref_email: "" }]);
  const [count, setCount] = useState(0);
  const [errors, SetErrors] = useState([]);

  const handleValidation = (fields) => {
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Please enter name";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Please enter email";
    }

    if(typeof fields["name"] !== "undefined") {
      const validateName = /^[A-Za-z,]+$/;
      if(validateName.test(fields["name"])) {

        if (typeof fields["email"] !== "undefined") {
          let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    
          if (filter.test(fields["email"])) {
            formIsValid = true;
          } else {
            formIsValid = false;
            errors["email"] = "Please enter email";
          }
        }

      } else {
        formIsValid = false;
        errors["name"] = "Name cannot be empty";
      }
    }

    SetErrors(errors);
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const list = [...fieldList];
    list[count][name] = value;
    console.log(e.target);
  };

  const handleRemoveClick = (index) => {
    const list = [...fieldList];
    list.splice(index, 1);
    setCount(count + -1);
    props.parentCallback(list);
    setfieldList(list);
  };

  const handleAddClick = (index) => {
    let data = {
      email: fieldList[index].ref_email,
      name: fieldList[index].ref_name,
    };

    if (handleValidation(data)) {
      if (fieldList[index].ref_name && fieldList[index].ref_email) {
        setCount(count + 1);
        setfieldList([...fieldList, {}]);
        props.parentCallback(fieldList);
        document.getElementById('email').value = '';
        document.getElementById('name').value = '';
      }
    }
  };
  return (
    <div className="inv-forms">
      <div className="field-strem">
        <div className="half">
          <div className="profile-input-block">
            <input
              type="text"
              name="ref_name"
              id="name"
              title="Name"
              placeholder="Name"
              value={fieldList.ref_name}
              onChange={(e) => handleInputChange(e)}
            />
            <span className="error">{errors["name"]}</span>
          </div>
        </div>
        <div className="half">
          <div className="profile-input-block">
            <input
              type="text"
              id="email"
              name="ref_email"
              title="Email"
              placeholder="Email"
              value={fieldList.ref_email}
              onChange={(e) => handleInputChange(e)}
            />
            <span className="error">{errors["email"]}</span>
          </div>
        </div>
        <a title="Add" className="addRefUser btn-refuser" href="javascript:void(0);">
          <img
            alt="NetProReferral"
            className="initial loading"
            src="https://dcywhuojnzfz0.cloudfront.net/assets/images/plus-img.png"
            data-was-processed="true"
            onClick={() => handleAddClick(count)}
          />
        </a>
        {fieldList.map((x, i) => {
          return (
            <div>
              {fieldList.length !== 1 && fieldList[i].ref_name && (
                <div className="refDiv">
                  <figure className="ref-user-img">
                    <img
                      alt="NetProReferral"
                      src="https://dcywhuojnzfz0.cloudfront.net/assets/images/man-user.png"
                      className="initial loaded"
                      data-was-processed="true"
                    />
                  </figure>
                  <div className="ref-align-right">
                    <div className="ref-user-name">{x.ref_name}</div>
                    <div className="ref-user-mail">{x.ref_email}</div>
                  </div>
                  <a title="Remove" className="removeRefUser btn-refuser">
                    <img
                      alt="NetProReferral"
                      src="https://dcywhuojnzfz0.cloudfront.net/assets/images/minus-img.png"
                      className="initial loaded"
                      data-was-processed="true"
                      onClick={() => handleRemoveClick(i)}
                    />
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default addUser;
