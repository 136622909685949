import React, { Component } from "react";
import DashBoard from "../Dashboard";
import Home from "../home";
import HowItWorks from "../howItworks";
import Blog from "../Blog";
import WriteForUS from "../writeforus";
import Terms from "../StaticPages/terms";
import Privacy from "../StaticPages/privacy";
import AboutUs from "../StaticPages/about";
import ContactUS from "../StaticPages/contact";
import PrivateRoute from "../../common/PrivateRoute";
import Referral from "../referral/giveReference";
import EditReferance from "../referral/editGivenReference";
import ForgotPassword from "../forgotPassword";
import { Switch, Route } from "react-router-dom";
import SeoAuditorIndex from "../SeoAuditor/SeoAuditorIndex";
import Professionals from "../professionals";
import ProfessionalDetails from '../ProfessionalDetails/ProfessionalDetails';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home}></Route>
    <Route exact path="/searchResults" component={Professionals}/>
    <Route path="/searchResults/:id" component={ProfessionalDetails} />
    <Route path="/howitworks" component={HowItWorks}></Route>
    <Route path="/reset-password/:token">
      <ForgotPassword />
    </Route>
    {!localStorage.getItem("isAuthenticated") && (
      <Route path="/login/:token">
        <Home />
      </Route>
    )}
    {localStorage.getItem("isAuthenticated") && (
      <Route path="/login/:token">
        <Referral />
      </Route>
    )}
    <Route path="/blog" component={Blog}></Route>
    {
      <Route path="/seoauditor">
        <SeoAuditorIndex />
      </Route>
    }

    <Route path="/writeforus">
      <WriteForUS />
    </Route>
    <Route path="/terms" component={Terms}></Route>
    <Route path="/privacy" component={Privacy}></Route>
    <Route path="/aboutus" component={AboutUs}></Route>
    <Route path="/contactus" component={ContactUS}></Route>

    <PrivateRoute
      authenticated={localStorage.getItem("isAuthenticated")}
      path="/editreference/:token"
      component={EditReferance}
      exact
    ></PrivateRoute>

    <PrivateRoute
      authenticated={localStorage.getItem("isAuthenticated")}
      path="/refer/:token"
      component={Referral}
      exact
    ></PrivateRoute>
    <PrivateRoute
      authenticated={() => localStorage.getItem("isAuthenticated")}
      path="/dashboard"
      component={DashBoard}
      exact
    ></PrivateRoute>
  </Switch>
);

export default Routes;
