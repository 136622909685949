import React, { Component, Fragment } from "react";
import "react-dropdown/style.css";
import { withRouter } from "react-router-dom";
import Search from "../Search/Search";

class Home extends Component {
  searchDataHandler = (data) => {
    this.props.history.push({
      pathname: "/searchResults",
      state: {
        data: data.referralData,
        searchText: data.searchText,
        location: data.location,
      },
    });
  };

  render() {
    return (
      <Fragment>
        <div className="forhomepageonly">
          <div className="relative">
            <a
              title="How Net Pro Referral Works"
              href="https://www.netproreferral.com/how-it-works#video"
              className="howit-banner effect-main "
            >
              {/* <i class="fa fa-play-circle-o video_icon" aria-hidden="true"></i> */}
              how it works
            </a>
            <div
              className="slider-bg"
              style={{
                backgroundPosition: "50% 50%",
                height: "620px",
                backgroundImage:
                  "url(https://dcywhuojnzfz0.cloudfront.net/assets/images/banner2.jpg)",
              }}
            ></div>
            <div>
              <div className="netpro-directory">
                <div className="adv-dir-head">Be Found on our map</div>
                <div className="calltoaction-reg calltoaction-center bfoundbtn">
                  <a className="claim-pop effect-main effect-ad-hover">
                    Claim Your Profile
                  </a>
                </div>
              </div>
              <div className="variantc-map">
                <img
                  alt=""
                  src="https://www.netproreferral.com/assets/images/variant-c.jpg"
                  className="initial loading"
                  data-was-processed="true"
                />
              </div>
            </div>
          </div>

          <Search getSearchData={this.searchDataHandler}></Search>
        </div>
      </Fragment>
    );
  }
}

Home.propTypes = {};

Home.defaultProps = {};

export default withRouter(Home);
